enum FileTypeEnum {
  None = 0,
  OrganizationLogoFile = 1,
  ProfilePhoto = 2,
  UserDocument = 3,
  GeneralDocument = 4,
  TicketDocument = 5,
}

export default FileTypeEnum;
