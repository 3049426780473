import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormAction, FormContainer, FormInput } from "../../components/high-level/form/index.js";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container.js";
import Validator from "../../components/high-level/validator/index.js";
import EventBus from "../../infrastructure/event-bus/event-bus.js";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers.js";
import general from "../../utils/general.js";

import EntityTypeEnum from "../../utils/enums/entity-type-enum.ts";

const TranslateFormModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();

  //#region State Operations

  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, setFormProps] = useState({});
  const updateFormProps = (values) => setFormProps((curr) => ({ ...curr, ...values }));

  const onChange = (name) => (value) => updateFormProps({ [name]: value });

  //#endregion

  //#region Api Calls

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "translate",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            updateFormProps(data);
          },
          callback: () => setLoading(false),
        })
      );
    }
  }, []);

  const save = useCallback(() => {
    const isValid = validator.allValid();
    reRender(1);
    if (isValid) {
      setLoading(true);
      if (general.isNullOrEmpty(id)) {
        // create
        dispatch(
          ApiCallActions.Save({
            controller: "translate",
            data: formProps,
            showLoading: true,
            onSuccess: async ({ data }) => {
              EventBus.sc.onEntityCreated({
                entityType: EntityTypeEnum.Translate,
                entityId: data,
              });
              closeModal?.();
              general.messageSuccess("general.save_success");
            },
            onError: (message) => general.notificationError(message.errorMessageTechnical),
            callback: () => setLoading(false),
          })
        );
      } else {
        // update
        dispatch(
          ApiCallActions.Update({
            controller: "translate",
            data: formProps,
            onSuccess: () => {
              EventBus.sc.onEntityUpdated({
                entityType: EntityTypeEnum.Translate,
                entityId: id,
              });
              closeModal?.();
              general.messageSuccess("general.update_success");
            },
            onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
            callback: () => setLoading(false),
          })
        );
      }
    }
  }, [formProps]);

  //#endregion

  return (
    <ModalFullWidthContainer
      customStyles={{
        marginTop: "100px",
        borderRadius: "15px",
        background: "#f5f5f5",
      }}
      nodeKey={nodeKey}
    >
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        footerActions={[
          {
            show: true,
            node: <FormAction customClassName={"save"} enableFunctionEqualityCheckForMemo loading={loading} text={i18next.t("form.button.save")} iconNode={<SaveOutlined />} buttonType="success" onClick={save} />,
          },
        ]}
        headerTitle={general.isNullOrEmpty(id) ? i18next.t("translate.form_title.add") : i18next.t("translate.form_title.edit")}
      >
        <div style={{ backgroundColor: "white", borderRadius: "15px", padding: "25px 20px", marginTop: 10 }} className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <FormInput
                addRequiredSign
                className="keyword"
                label={i18next.t("translate.form.keyword")}
                placeholder={i18next.t("translate.form.keyword")}
                onChange={onChange("keyword")}
                value={formProps?.keyword}
                errorMessage={validator.register("keyword", formProps?.keyword, [{ rule: "required" }], validatorScopeKey)}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <FormInput
                addRequiredSign
                className="translation"
                label={i18next.t("translate.form.translation")}
                placeholder={i18next.t("translate.form.translation")}
                onChange={onChange("translation")}
                value={formProps?.translation}
                errorMessage={validator.register("translation", formProps?.translation, [{ rule: "required" }], validatorScopeKey)}
              />
            </div>
          </div>
        </div>
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default TranslateFormModal;
