enum EntityTypeEnum {
  User = 1,
  Log = 2,
  Workplace = 3,
  DocumentType = 4,
  Ticket = 5,
  Translate = 6,
  UserDocument = 7,
  GeneralDocument = 8,
  Subcontractor = 9,
  ScreenRecordLog = 10,
  Customer = 11,
  WorkingTime = 12,
}

export default EntityTypeEnum;
