import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../../../components/high-level/rodal/rodal.css";
import ChangePasswordModal from "../../../modals/change-password/change-password-form";
import CheckCodeModal from "../../../modals/check-code";
import CropFileModal from "../../../modals/crop-file/crop-file-modal";
import CustomerFormModal from "../../../modals/customer/customer-form";
import DocumentTypeFormModal from "../../../modals/document-type/document-type-form";
import GeneralDocumentModal from "../../../modals/general-document/general-documents-form";
import LogFormModal from "../../../modals/log-record/log-form";
import ResetPasswordModal from "../../../modals/reset-password";
import SubcontractorFormModal from "../../../modals/subcontractor/subcontractor-form";
import TicketDetailModal from "../../../modals/ticket/ticket-detail";
import TranslateFormModal from "../../../modals/translate/translate-form";
import UserDocumentsFormModal from "../../../modals/user-documents/user-documents-form";
import UserDetailModal from "../../../modals/user/user-detail-form";
import UserFormModal from "../../../modals/user/user-form";
import WorkplaceDetailModal from "../../../modals/workplace/workplace-detail-form";
import WorkplaceFormModal from "../../../modals/workplace/workplace-form";
import { Actions as ModalActions } from "../../../redux/modal/reducers";
import ModalTypes from "../../../utils/modal-types";

const ModalRenderContainer = ({ }) => {
  const dispatch = useDispatch();
  const modals = useSelector(state => state.modal.modals);
  const history = useHistory()
  useEffect(() => {
    return history.listen((location) => {
      dispatch(ModalActions.setModalState({ modals: [] }))
    })
  }, [history])

  const closeModal = (nodeKey) => () => {
    dispatch(ModalActions.closeModal({ nodeKey }))
  }

  return (
    <div>
      {modals?.map(item => <div key={item?.nodeKey}>
        {item.type === ModalTypes.USER_FORM &&
          <UserFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.LOG_FORM &&
          <LogFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.WORKPLACE_FORM &&
          <WorkplaceFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.CHECK_CODE &&
          <CheckCodeModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.RESET_PASSWORD &&
          <ResetPasswordModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.DOCUMENT_TYPE_FORM &&
          <DocumentTypeFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.TICKET_DETAIL &&
          <TicketDetailModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.TRANSLATE_FORM &&
          <TranslateFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.USER_DOCUMENTS_FORM &&
          <UserDocumentsFormModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
         {item.type === ModalTypes.GENERAL_DOCUMENTS_FORM &&
          <GeneralDocumentModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.CHANGE_PASSWORD_FORM &&
          <ChangePasswordModal key={item?.nodeKey + "rM"} nodeKey={item?.nodeKey} {...item.modalProps} closeModal={closeModal(item?.nodeKey)} />
        }
        {item.type === ModalTypes.CROP_FILE_MODAL &&
          <CropFileModal key={item.nodeKey + "rM"} nodeKey={item.nodeKey} {...item.modalProps} closeModal={closeModal(item.nodeKey)} />
        }
         {item.type === ModalTypes.SUBCONTRACTOR_FORM &&
          <SubcontractorFormModal key={item.nodeKey + "rM"} nodeKey={item.nodeKey} {...item.modalProps} closeModal={closeModal(item.nodeKey)} />
        }
        {item.type === ModalTypes.CUSTOMER_FORM &&
          <CustomerFormModal key={item.nodeKey + "rM"} nodeKey={item.nodeKey} {...item.modalProps} closeModal={closeModal(item.nodeKey)} />
        }
        {item.type === ModalTypes.USER_DETAIL_FORM &&
          <UserDetailModal key={item.nodeKey + "rM"} nodeKey={item.nodeKey} {...item.modalProps} closeModal={closeModal(item.nodeKey)} />
        }
        {item.type === ModalTypes.WORKPLACE_DETAIL_FORM &&
          <WorkplaceDetailModal key={item.nodeKey + "rM"} nodeKey={item.nodeKey} {...item.modalProps} closeModal={closeModal(item.nodeKey)} />
        }
      </div>)}
    </div>)
}

export default ModalRenderContainer;
