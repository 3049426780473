import ModalFullWidthOverlay from "../../components/high-level/modal/modal-full-overlay";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import general from "../../utils/general";
import Validator from "../../components/high-level/validator";
import FormInputPassword from "../../components/high-level/form/form-input-password";

const ResetPasswordModal = ({ nodeKey, closeModal, email, verificationCode, onConfirm }) => {
  const [validator] = useState(new Validator());
  const validatorScopeKey = useMemo(() => validator.generateNewScopeKey(), []);
  const dispatch = useDispatch();

  const [formProps, setFormProps] = useState({
    email,
    verificationCode,
  });

  const onChange = (key) => (value) => setFormProps((curr) => ({ ...curr, [key]: value }));

  const checkCode = () => {
    if (validator.allValid()) {
      dispatch(
        ApiCallActions.NativePost({
          controller: "auth",
          action: "changePassword",
          data: formProps,
          showAlertOnError: true,
          showLoading: true,
          onSuccess: ({ data }) => {
            if (data) {
              general.notificationSuccess("general.password_changed_successfully");
              closeModal();
              if (onConfirm instanceof Function) onConfirm();
            } else general.notificationError("general.error_occured");
          },
          onError: (x) => console.log("err", x),
        })
      );
    }
    setFormProps((curr) => ({ ...curr, reRender: Math.random() }));
  };

  return (
    <ModalFullWidthOverlay nodeKey={nodeKey} useLargeModal={false} padding="0" handleOk={checkCode} title={i18next.t("general.reset_password")} handleCancel={closeModal}>
      <div style={{marginBottom: 20}}>
        <FormInputPassword
          key={"password"}
          addRequiredSign
          label={i18next.t("general.password")}
          value={formProps.password}
          onChange={onChange("password")}
          errorMessage={validator.register("password", formProps.password, [{ rule: "required" }, { rule: "minStringLength", value: 6 }], validatorScopeKey)}
        />
      </div>
      <div>
        <FormInputPassword
          key={"checkPassword"}
          addRequiredSign
          label={i18next.t("general.check_password")}
          value={formProps.checkPassword}
          generatePasswordActive={false}
          onChange={onChange("checkPassword")}
          errorMessage={validator.register("checkPassword", formProps.checkPassword, [{ rule: "required" }, { rule: "areEqual", value: formProps.password }], validatorScopeKey)}
        />
      </div>
    </ModalFullWidthOverlay>
  );
};

export default ResetPasswordModal;
