import React, { useEffect, useState } from "react";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import { useDispatch } from "react-redux";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container";
import Validator from "../../components/high-level/validator";
import { FormAction, FormContainer } from "../../components/high-level/form";
import { CloseOutlined } from "@ant-design/icons";
import general from "../../utils/general";
import i18next from "i18next";
import useRefState from "../../utils/use-ref-state";
import SubHeader from "../../components/low-level/sub-header";
import ActionTypeEnum from "../../utils/enums/action-type-enum.ts";
import DataTypeEnum from "../../utils/enums/data-type-enum.ts";

const LogFormModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();

  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, formPropsRef, setFormProps] = useRefState({});
  const updateFormProps = (values) => {
    setFormProps((curr) => ({ ...curr, ...values }));
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "log",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            var newData = {
              ...data,
              changes: JSON.parse(data?.changes),
            };
            setLoading(false);
            setFormProps(newData);
          },
        })
      );
    }
  }, []);

  return (
    <ModalFullWidthContainer nodeKey={nodeKey}>
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction text={i18next.t("form.button.close")} iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        headerTitle={i18next.t("log.form_title.detail")}
      >
        <div className="col-12 col-sm-6 col-md-3">
          <b>{i18next.t("log.form.module")}:</b> {i18next.t(formProps?.moduleTypeName)}
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <b>{i18next.t("log.form.action_type")}:</b> {i18next.t(formProps?.actionTypeName)}
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <b>{i18next.t("log.form.created_user")}:</b> {formProps?.createdUser?.fullName}
        </div>
        <div className="col-12 col-sm-6 col-md-3">
          <b>{i18next.t("log.form.created_date")}:</b> {general.apiDateFormatToUserFormat(formProps?.createdDate, true)}
        </div>

        <SubHeader header={i18next.t("log.form.changes")} />
        {!(formProps?.changes?.length >= 1) && (
          <div className="col-12">
            <h6 className="text-center">{i18next.t("log.form.properties_not_changed")}</h6>
          </div>
        )}

        {formProps?.changes?.length >= 1 && (
          <div className="col-12">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>{i18next.t("log.form.property")}</th>
                  <th>{i18next.t("log.form.new_value")}</th>
                  {formProps?.actionType != ActionTypeEnum.Create && <th>{i18next.t("log.form.old_value")}</th>}
                </tr>
              </thead>
              <tbody>
                {formProps?.changes?.map((change, index) => {
                  return (
                    <>
                      {!general.isNullOrEmpty(change?.newValue) && !general.isNullOrEmpty(change?.newValue) && (
                        <tr key={index}>
                          <td>{i18next.t(change.propertyName)}</td>
                          {(change?.dataType == DataTypeEnum.Decimal || change?.dataType == DataTypeEnum.Numeric || change?.dataType == DataTypeEnum.String) && (
                            <>
                              <td>{change.newValue}</td>
                              {formProps?.actionType != ActionTypeEnum.Create && <td>{change.oldValue}</td>}
                            </>
                          )}
                          {change?.dataType == DataTypeEnum.Bool && (
                            <>
                              <td>{change.newValue == "True" ? i18next.t("general.yes") : change.newValue == "False" ? i18next.t("general.no") : i18next.t("general.null")}</td>
                              {formProps?.actionType != ActionTypeEnum.Create && <td>{change?.oldValue == "True" ? i18next.t("general.yes") : change.oldValue == "False" ? i18next.t("general.no") : i18next.t("general.null")}</td>}
                            </>
                          )}
                          {change?.dataType == DataTypeEnum.Enum && (
                            <>
                              <td>{i18next.t(change.newValue)}</td>
                              {formProps?.actionType != ActionTypeEnum.Create && <td>{i18next.t(change.oldValue)}</td>}
                            </>
                          )}
                          {change?.dataType == DataTypeEnum.Datetime && (
                            <>
                              <td>{general.apiDateFormatToUserFormat(change.newValue, true)}</td>
                              {formProps?.actionType != ActionTypeEnum.Create && <td>{general.apiDateFormatToUserFormat(change.oldValue, true)}</td>}
                            </>
                          )}
                          {change?.dataType == DataTypeEnum.Image && (
                            <>
                              <td>
                                {JSON.parse(change?.newValue)?.map((item, i) => (
                                  <img src={item?.downloadUrl} style={{ maxWidth: "100px", padding: "0 5px" }} />
                                ))}
                              </td>
                              {formProps?.actionType != ActionTypeEnum.Create && (
                                <td>
                                  {JSON.parse(change?.oldValue)?.map((item, i) => (
                                    <img src={item?.downloadUrl} style={{ maxWidth: "100px", padding: "0 5px" }} />
                                  ))}
                                </td>
                              )}
                            </>
                          )}
                          {change?.dataType == DataTypeEnum.Json && (
                            <>
                              <td>
                                {JSON.parse(change?.newValue)?.map((item, i) => (
                                  <p className="mb-0">
                                    {item?.AuthorizedPeopleTypeName}: {item?.Value}{" "}
                                  </p>
                                ))}
                              </td>
                              {formProps?.actionType != ActionTypeEnum.Create && (
                                <td>
                                  {JSON.parse(change?.oldValue)?.map((item, i) => (
                                    <p className="mb-0">
                                      {" "}
                                      {item?.AuthorizedPeopleTypeName}: {item?.Value}{" "}
                                    </p>
                                  ))}
                                </td>
                              )}
                            </>
                          )}
                        </tr>
                      )}
                    </>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default LogFormModal;
