import { CloseOutlined, EnvironmentOutlined, MailOutlined, PhoneOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormAction, FormContainer, FormInput } from "../../components/high-level/form/index.js";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container.js";
import Validator from "../../components/high-level/validator/index.js";
import EventBus from "../../infrastructure/event-bus/event-bus.js";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers.js";
import general from "../../utils/general.js";

import FormSelect from "../../components/high-level/form/form-select.js";
import EntityTypeEnum from "../../utils/enums/entity-type-enum.ts";

const CustomerFormModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();

  //#region State Operations

  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, setFormProps] = useState({});
  const updateFormProps = (values) => setFormProps((curr) => ({ ...curr, ...values }));

  const onChange = (name) => (value) => updateFormProps({ [name]: value });

  //#endregion

  const OptionItem = ({ option }) => {
    return (
      <div className="option-item">
        <img src={option?.image} alt={option.name} style={{ width: 25, height: 15, paddingRight: 5 }} />
        <span className="option-item-name">{option.name}</span>
      </div>
    );
  };

  //#region Api Calls

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "customer",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            let _data = {
              ...data,
              countryCode: {
                name: data?.authorisedPhone?.split(" ")[0],
                value: data?.authorisedPhone?.split(" ")[0],
                id: data?.authorisedPhone?.split(" ")[0],
                image: require("../../assets/flags/flags.json")?.find((x) => x.name === data?.authorisedPhone?.split(" ")[0])?.image,
              },
              authorisedPhone: data?.authorisedPhone?.split(" ")[1],
            };
            updateFormProps(_data);
          },
          callback: () => setLoading(false),
        })
      );
    }
  }, []);

  const save = useCallback(() => {
    const isValid = validator.allValid();
    let formData = { ...formProps, authorisedPhone: formProps?.countryCode?.name + " " + formProps?.authorisedPhone };
    reRender(1);
    if (isValid) {
      setLoading(true);
      if (general.isNullOrEmpty(id)) {
        // create
        dispatch(
          ApiCallActions.Save({
            controller: "customer",
            data: formData,
            showLoading: true,
            onSuccess: async ({ data }) => {
              EventBus.sc.onEntityCreated({
                entityType: EntityTypeEnum.Customer,
                entityId: data,
              });
              closeModal?.();
              general.messageSuccess("general.save_success");
            },
            onError: (message) => general.notificationError(message.errorMessageTechnical),
            callback: () => setLoading(false),
          })
        );
      } else {
        // update
        dispatch(
          ApiCallActions.Update({
            controller: "customer",
            data: formData,
            onSuccess: () => {
              EventBus.sc.onEntityUpdated({
                entityType: EntityTypeEnum.Customer,
                entityId: id,
              });
              closeModal?.();
              general.messageSuccess("general.update_success");
            },
            onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
            callback: () => setLoading(false),
          })
        );
      }
    }
  }, [formProps]);

  //#endregion

  return (
    <ModalFullWidthContainer
      customStyles={{
        marginTop: "100px",
        borderRadius: "15px",
        background: "#f5f5f5",
      }}
      nodeKey={nodeKey}
    >
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        footerActions={[
          {
            show: true,
            node: <FormAction customClassName={"save"} enableFunctionEqualityCheckForMemo loading={loading} text={i18next.t("form.button.save")} iconNode={<SaveOutlined />} buttonType="success" onClick={save} />,
          },
        ]}
        headerTitle={general.isNullOrEmpty(id) ? i18next.t("customer.form_title.add") : i18next.t("customer.form_title.edit")}
      >
        <div style={{ backgroundColor: "white", borderRadius: "15px", padding: "25px 20px", marginTop: 10 }} className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6">
              <FormInput
                addRequiredSign
                className="name"
                label={i18next.t("customer.form.name")}
                placeholder={i18next.t("customer.form.name")}
                onChange={onChange("name")}
                value={formProps?.name}
                errorMessage={validator.register("name", formProps?.name, [{ rule: "required" }, { rule: "maxStringLength", value: 100 }], validatorScopeKey)}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-12 mt-3">
              <FormInput
                addRequiredSign
                className="address"
                label={i18next.t("customer.form.address")}
                placeholder={i18next.t("customer.form.address")}
                onChange={onChange("address")}
                value={formProps?.address}
                prefix={<EnvironmentOutlined />}
                errorMessage={validator.register("address", formProps?.address, [{ rule: "required" }, { rule: "maxStringLength", value: 250 }], validatorScopeKey)}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormInput
                addRequiredSign
                className="authorisedName"
                label={i18next.t("customer.form.authorised_name")}
                placeholder={i18next.t("customer.form.authorised_name")}
                onChange={onChange("authorisedName")}
                value={formProps?.authorisedName}
                prefix={<UserOutlined />}
                errorMessage={validator.register("authorisedName", formProps?.authorisedName, [{ rule: "required" }, { rule: "maxStringLength", value: 50 }], validatorScopeKey)}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormInput
                addRequiredSign
                className="authorisedEmail"
                label={i18next.t("customer.form.authorised_email")}
                placeholder={i18next.t("customer.form.authorised_email")}
                onChange={onChange("authorisedEmail")}
                value={formProps?.authorisedEmail}
                prefix={<MailOutlined />}
                errorMessage={validator.register("authorisedEmail", formProps?.authorisedEmail, [{ rule: "required" }, { rule: "isEmail" }, { rule: "maxStringLength", value: 50 }], validatorScopeKey)}
              />
            </div>
            <div className="col-12 col-sm-4 col-md-4 col-lg-3 mt-3">
              <FormSelect
                addRequiredSign
                customData={require("../../assets/flags/flags.json")}
                isUiFilter={true}
                label={i18next.t("customer.form.country_code")}
                selectedId={formProps?.countryCode}
                selectedName={i18next.t(formProps?.countryCode)}
                getOptionLabel={(option) => <OptionItem option={option} />}
                onChange={onChange("countryCode")}
                value={formProps?.countryCode}
                errorMessage={validator.register("countryCode", formProps?.countryCode, [{ rule: "required" }], validatorScopeKey)}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-3 mt-3">
              <FormInput
                addRequiredSign
                maxLength={15}
                className="authorisedPhone"
                label={i18next.t("customer.form.authorised_phone")}
                placeholder={i18next.t("customer.form.authorised_phone")}
                onChange={onChange("authorisedPhone")}
                value={formProps?.authorisedPhone}
                prefix={<PhoneOutlined />}
                errorMessage={validator.register("authorisedPhone", formProps?.authorisedPhone, [{ rule: "required" }, { rule: "isNumber" }], validatorScopeKey)}
              />
            </div>
          </div>
        </div>
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default CustomerFormModal;
