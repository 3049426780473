enum DataTypeEnum {
    Numeric = 1,
    String = 2,
    Datetime = 3,
    Bool = 4,
    Enum = 5,
    Decimal = 6,
    Json = 7,
    Image = 8,
}

export default DataTypeEnum;