import React, { useState } from "react";

import "./index.css";

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, disabled, date, addRequiredSign } = props;
  let labelClass = null;
  if (date) {
    labelClass = disabled ? "label-date label-float-disabled" : focus || ((value || value == 0) && value.length !== 0) ? "label label-float" : "label label-float";
  } else {
    labelClass = disabled ? "label label-float-disabled" : focus || ((value || value == 0) && value.length !== 0) ? "label label-float" : "label";
  }

  return (
    <div className="float-label" style={{ display: "flex", flexDirection: "row" }} onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      {children}
      <label
        style={{
          color: "black",
          fontWeight: "600",
          fontSize: "14px",
          display: "flex",
          flexDirection: "row",
        }}
        className={labelClass}
      >
        {label}
        {addRequiredSign && (
          <div
            style={{
              color: "#E30613",
            }}
          >
            *
          </div>
        )}
      </label>
    </div>
  );
};

export default FloatLabel;
