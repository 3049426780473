import { DatePicker, Form } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import FloatLabel from "../../low-level/float-label";
import general from "../../../utils/general";

const FormDatePicker = ({
  label,
  value,
  minDate,
  maxDate,
  errorMessage,
  disabled,
  onChange,
  rightAction,
  style,
  dayRange,
  showTime = false,
  dateFormat = showTime ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY",
  defaultPickerValue,
  helperText, // Bilgilendirme metini,
  addRequiredSign = false,
  picker = "date",
  allowClear = true
}) => {
  const onCahngeEvent = (e) => {
    if (onChange instanceof Function) onChange(e ? e.startOf(showTime ? "second" : "day").toISOString() : null);
  };

  let minDateHook = null;
  let maxDateHook = null;

  if (!dayRange) {
    minDateHook = general.isNullOrEmpty(minDate) ? null : dayjs(minDate).startOf(showTime ? "second" : "day");
    maxDateHook = general.isNullOrEmpty(maxDate) ? null : dayjs(maxDate).startOf(showTime ? "second" : "day");
  } else {
    minDateHook = general.isNullOrEmpty(minDate) ? null : dayjs(minDate).startOf("day");
    maxDateHook = general.isNullOrEmpty(maxDate) ? null : dayjs(maxDate).startOf("day");
  }

  const disabledDate = (current) => {
    if (!dayRange) {
      if (minDateHook && current && minDateHook > dayjs(current).startOf(showTime ? "second" : "day")) return true;
      if (maxDateHook && current && maxDateHook < dayjs(current).startOf(showTime ? "second" : "day")) return true;
      return false;
    } else {
      if (minDateHook && current && minDateHook > dayjs(current).startOf("day")) return true;
      if (maxDateHook && current && maxDateHook < dayjs(current).startOf("day")) return true;
      return false;
    }
  };
  return (
    <Form.Item
      /*  label={label} */
      validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
      help={errorMessage ? errorMessage : helperText ?? helperText}
      style={{
        ...style,
        border: "none",
        borderRadius: "8px",
      }}
    >
      <FloatLabel addRequiredSign label={label} name={label} value={value ? dayjs(value) : null} disabled={disabled}>
        <DatePicker
          showTime={showTime}
          showSecond={false}
          style={{ display: "block", width: "100%", borderColor: errorMessage ? "#f5222e" : "" }}
          disabledDate={disabledDate}
          disabled={disabled}
          value={value ? dayjs(value) : null}
          size="large"
          allowClear={allowClear}
          onChange={onCahngeEvent}
          className="border-style"
          format={dateFormat}
          defaultPickerValue={defaultPickerValue}
          picker={picker}
          // locale={general.getLanguage()}
          placeholder=""
        />
        {rightAction}
      </FloatLabel>
    </Form.Item>
  );
};

const areEqual = (prev, next) => {
  return (
    prev.label === next.label &&
    JSON.stringify(prev.value) === JSON.stringify(next.value) &&
    JSON.stringify(prev.minDate) === JSON.stringify(next.minDate) &&
    JSON.stringify(prev.maxDate) === JSON.stringify(next.maxDate) &&
    JSON.stringify(prev.style) === JSON.stringify(next.style) &&
    prev.placeholder === next.placeholder &&
    prev.dateFormat === next.dateFormat &&
    prev.errorMessage === next.errorMessage &&
    prev.disabled === next.disabled
  );
};
export default React.memo(FormDatePicker, areEqual);
