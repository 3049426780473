import { CloseOutlined, SaveOutlined, EnvironmentOutlined } from "@ant-design/icons";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormAction, FormContainer, FormInput } from "../../components/high-level/form/index.js";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container.js";
import Validator from "../../components/high-level/validator/index.js";
import EventBus from "../../infrastructure/event-bus/event-bus.js";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers.js";
import general from "../../utils/general.js";

import FormSelect from "../../components/high-level/form/form-select.js";
import EntityTypeEnum from "../../utils/enums/entity-type-enum.ts";
import StatusEnum from "../../utils/enums/status-enum.ts";
import UserTypeEnum from "../../utils/enums/user-type-enum.ts";

const WorkplaceFormModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.auth.userInfo);
  //#region State Operations

  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, setFormProps] = useState({});
  const updateFormProps = (values) => setFormProps((curr) => ({ ...curr, ...values }));

  const onChange = (name) => (value) => updateFormProps({ [name]: value });
  const onChangeSiteManager = useCallback((val) => {
    updateFormProps({ workPlaceManagers: val });
  }, []);
  const onChangeSubcontractor = useCallback((val) => {
    updateFormProps({ subContractors: val });
    // updateFormProps({ subContractors: val, workPlaceManagers: [] });
  }, []);
  const onChangeCustomer = useCallback((val) => {
    updateFormProps({ customer: val });
  }, []);

  //#endregion

  //#region Api Calls

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "workplace",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            let _data = {
              ...data,
              workPlaceManagers: data?.workPlaceUsers
                ?.filter((_item) => _item?.user)
                ?.map((item) => ({
                  name: item?.user?.name,
                  id: item?.userId,
                  value: item?.userId,
                })),
              subContractors: data?.workPlaceSubContractors?.map((item) => ({
                name: item?.subContractor?.name,
                id: item?.subContractorId,
                value: item?.subContractorId,
              })),
            };
            updateFormProps(_data);
          },
          callback: () => setLoading(false),
        })
      );
    }
  }, []);
  const save = useCallback(() => {
    const isValid = validator.allValid();
    reRender(1);
    if (isValid) {
      setLoading(true);

      let formData = {
        siteManagers: formProps?.workPlaceManagers?.map((item) => item.id),
        subContractors: formProps?.subContractors?.map((item) => item.id),
        name: formProps?.name,
        address: formProps?.address,
        customerId: formProps?.customer?.id ?? null,
      };
      if (general.isNullOrEmpty(id)) {
        // create
        dispatch(
          ApiCallActions.Save({
            controller: "workplace",
            data: formData,
            showLoading: true,
            onSuccess: async ({ data }) => {
              EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.Workplace, entityId: data });
              closeModal?.();
              general.messageSuccess("general.save_success");
            },
            onError: (message) => general.notificationError(message.errorMessageTechnical),
            callback: () => setLoading(false),
          })
        );
      } else {
        // update
        formData = { ...formData, id: id };
        dispatch(
          ApiCallActions.Update({
            controller: "workplace",
            data: formData,
            onSuccess: () => {
              EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.Workplace, entityId: id });
              closeModal?.();
              general.messageSuccess("general.update_success");
            },
            onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
            callback: () => setLoading(false),
          })
        );
      }
    }
  }, [formProps]);
  //#endregion

  return (
    <ModalFullWidthContainer
      customStyles={{
        marginTop: "100px",
        borderRadius: "15px",
        background: "#f5f5f5",
      }}
      nodeKey={nodeKey}
    >
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        footerActions={[
          {
            show: true,
            node: <FormAction customClassName={"save"} enableFunctionEqualityCheckForMemo loading={loading} text={i18next.t("form.button.save")} iconNode={<SaveOutlined />} buttonType="success" onClick={save} />,
          },
        ]}
        headerTitle={general.isNullOrEmpty(id) ? i18next.t("workplace.form_title.add") : i18next.t("workplace.form_title.edit")}
      >
        <div style={{ backgroundColor: "white", borderRadius: "15px", padding: "25px 20px", marginTop: 10 }} className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormInput
                addRequiredSign
                className="name"
                label={i18next.t("workplace.form.name")}
                placeholder={i18next.t("workplace.form.name")}
                onChange={onChange("name")}
                value={formProps?.name}
                errorMessage={validator.register("name", formProps?.name, [{ rule: "required" }, { rule: "maxStringLength", value: 50 }], validatorScopeKey)}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormInput
                addRequiredSign
                className="address"
                label={i18next.t("workplace.form.address")}
                placeholder={i18next.t("workplace.form.address")}
                onChange={onChange("address")}
                value={formProps?.address}
                prefix={<EnvironmentOutlined />}
                errorMessage={validator.register("address", formProps?.address, [{ rule: "required" }, { rule: "maxStringLength", value: 250 }], validatorScopeKey)}
              />
            </div>

            {!userInfo?.subContractorId && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                <FormSelect
                  className="subcontractor"
                  controller="subContractor"
                  isUiFilter={true}
                  action={"dropdown"}
                  isMulti
                  label={i18next.t("user.form.subcontractor")}
                  selectedId={formProps?.subContractors?.map((item) => item.id)}
                  selectedName={formProps?.subContractors?.map((item) => item.name)}
                  value={formProps?.subContractors}
                  onChange={onChangeSubcontractor}
                  filter={{ status: StatusEnum.Active }}
                />
              </div>
            )}
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormSelect
                className="siteManager"
                controller="user"
                isUiFilter={true}
                action={"dropdown"}
                isMulti
                label={i18next.t("user.form.site_manager")}
                value={formProps?.workPlaceManagers}
                selectedId={formProps?.workPlaceManagers?.map((item) => item.id)}
                selectedName={formProps?.workPlaceManagers?.map((item) => item.id)}
                onChange={onChangeSiteManager}
                filter={{ userType: UserTypeEnum.SiteManager, status: StatusEnum.Active }}
                // filter={{ userType: UserTypeEnum.SiteManager, status: StatusEnum.Active, subContractorId: formProps?.subContractor?.id }}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormSelect className="customer" controller="customer" isUiFilter={true} action={"dropdown"} label={i18next.t("user.form.customer")} selectedId={formProps?.customer?.id} selectedName={formProps?.customer?.name} value={formProps?.customer} onChange={onChangeCustomer} />
            </div>
          </div>
        </div>
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default WorkplaceFormModal;
