import React, { useMemo } from "react";
import { Button, Popconfirm, Tooltip } from "antd";
import GridAction from "./grid-action";
import i18next from "i18next";
import { putResolve } from "redux-saga/effects";
import "./style.css";

const GridPopConfirmAction = ({
  //grid action component props
  actionProps = {
    tooltip: null,
    iconName: null,
    buttonType: "info",
    iconColor: null,
    backgroundColor: null,
    text: null,
    iconNode: null,
  },
  //

  onClickYes,
  onClickNo,
  confirmationText = "general.are_you_sure",
  yesButtonText = "action.yes",
  noButtonText = "action.no",
  disabled,
  enableCheckEqualityOfOnClickYes = false,
}) => {
  const clickYes = () => {
    if (onClickYes instanceof Function) onClickYes();
  };

  const clickNo = () => {
    if (onClickNo instanceof Function) onClickNo();
  };

  const buttonProps = useMemo(() => {
    return {
      cursor: actionProps?.cursor,
      style: {
        cursor: actionProps?.cursor,
        marginLeft: 3,
        color: "default",
        borderWidth: 0,
        borderRight: "solid",
        borderLeft: "solid",
        borderRightColor: !actionProps?.rightBorder ? "transparent" : "#eee",
        borderLeftColor: !actionProps?.leftBorder ? "transparent" : "#eee",
      },
      color: actionProps?.backgroundColor,
      icon: actionProps?.iconNode ? actionProps?.iconNode : actionProps?.iconName ? <i className={actionProps?.iconName} /> : null,
      className: `btn btn-sm btn-${actionProps?.buttonType}`,
      size: "middle",
    };
  }, [actionProps]);

  return (
    <>
      <Tooltip placement="bottom" title={actionProps?.tooltip}>
        <Popconfirm
          title={i18next.t(confirmationText)}
          onConfirm={clickYes}
          onCancel={clickNo}
          okType="danger"
          okText={i18next.t(yesButtonText)}
          cancelText={i18next.t(noButtonText)}
          disabled={disabled}
          cancelButtonProps={{ style: { maxHeight: 30 } }}
          okButtonProps={{ style: { maxHeight: 30 } }}
          overlayStyle={{ maxWidth: 350 }}
        >
          <Button className="buttons" {...buttonProps} />
        </Popconfirm>
      </Tooltip>
    </>
  );
};

const areEqual = (prev, next) => {
  if (prev.enableCheckEqualityOfOnClickYes && prev.onClickYes !== next.onClickYes) return false;
  return prev.confirmationText === next.confirmationText && prev.yesButtonText === next.yesButtonText && prev.noButtonText === next.noButtonText && prev.disabled === next.disabled && prev?.actionProps?.disabled === next?.actionProps?.disabled;

  // && JSON.stringify(prev.actionProps) === JSON.stringify(next.actionProps)
};

export default React.memo(GridPopConfirmAction, areEqual);
