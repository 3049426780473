import { useMemo } from "react";
import { useSelector } from "react-redux";
import UserTypeEnum from "../../../utils/enums/user-type-enum";
import general from "../../../utils/general";

const useUserType = () => {
  const userInfo = useSelector((x) => x.auth.userInfo);

  const isEmployee = useMemo(() => userInfo?.userType === UserTypeEnum.Employee, [userInfo]);
  const isSiteManager = useMemo(() => userInfo?.userType === UserTypeEnum.SiteManager, [userInfo]);
  const isCustomer = useMemo(() => userInfo?.userType === UserTypeEnum.Administrator && !general.isNullOrEmpty(userInfo?.customerId) && general.isNullOrEmpty(userInfo?.subContractorId), [userInfo]);
  const isAdmin = useMemo(() => userInfo?.userType === UserTypeEnum.Administrator && general.isNullOrEmpty(userInfo?.customerId) && general.isNullOrEmpty(userInfo?.subContractorId), [userInfo]);
  const isSubcontractor = useMemo(() => userInfo?.userType === UserTypeEnum.Administrator && !general.isNullOrEmpty(userInfo?.subContractorId) && general.isNullOrEmpty(userInfo?.customerId), [userInfo]);

  return {
    isEmployee,
    isSiteManager,
    isCustomer,
    isAdmin,
    isSubcontractor,
  };
};
export default useUserType;

//parentOrganization
