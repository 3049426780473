import { Spin } from "antd";
import i18next from "i18next";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../components/high-level/modal/custom-modal";
import useUserType from "../../infrastructure/event-bus/hooks/use-user-type";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import general from "../../utils/general";

const UserDetailModal = ({ id, nodeKey, handleOk }) => {
  const dispatch = useDispatch();
  const { isSubcontractor, isCustomer } = useUserType();
  const [isOpen, setIsOpen] = useState(true);
  const [formProps, setFormProps] = useState({});
  const [loading, setLoading] = useState(false);

  const Styles = {
    body: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    container: {
      //   padding: '20px',
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "rgb(255 255 255)",
      textAlign: "center",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#E30613",
      borderTopLeftRadius: 9,
      borderTopRightRadius: 9,
      letterSpacing: "0.4px",
    },
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "user",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            setLoading(false);
            setFormProps(data);
          },
        })
      );
    }
  }, []);
  return (
    <CustomModal nodeKey={nodeKey} isOpen={isOpen} footer={null} width={800}>
      <>
        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
          <div className="col-lg-12" style={Styles.body}>
            <div className="col-lg-11" style={Styles.container}>
              <div style={Styles.title}>{i18next.t("user_detail.title")}</div>
              {loading ? (
                <div style={{ display: "flex", alignSelf: "center", paddingLeft: "48%", paddingBlock: 50 }}>
                  <Spin style={Styles.spinner} />
                </div>
              ) : (
                <div style={{ paddingLeft: 20 }}>
                  {formProps?.profilePhoto && <img src={formProps?.profilePhoto} style={{ width: "180px" }} className="mr-2 mt-3" alt="Heinle" />}

                  <div
                    className="col-lg-12"
                    style={{
                      alignItems: "center",
                      minHeight: 40,
                      display: "flex",
                    }}
                  >
                    <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.user_type")}:</div>
                    <div style={{ fontSize: 15, marginLeft: 4 }}>{i18next.t(`UserTypeEnum.${formProps?.userType}`)}</div>
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      alignItems: "center",
                      minHeight: 40,
                      display: "flex",
                    }}
                  >
                    <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.name")}:</div>
                    <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.name}</div>
                  </div>

                  <div
                    className="col-lg-12"
                    style={{
                      alignItems: "center",
                      minHeight: 40,
                      display: "flex",
                    }}
                  >
                    <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.email")}:</div>
                    <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.email}</div>
                  </div>
                  {formProps?.birthDay && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.birth_date")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{moment(formProps?.birthDay).format("DD.MM.YYYY")}</div>
                    </div>
                  )}
                  {formProps?.address && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.address")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.address}</div>
                    </div>
                  )}
                  {formProps?.subContractor && !isCustomer && !isSubcontractor && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.subcontractor")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.subContractor?.name}</div>
                    </div>
                  )}
                  {formProps?.workPlaceUsers?.length > 0 && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.workplace")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.workPlaceUsers?.map((workplace) => workplace?.workPlace?.name).join(", ")}</div>
                    </div>
                  )}
                  {formProps?.customer && !isCustomer && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.customer")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.customer?.name}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </CustomModal>
  );
};

export default UserDetailModal;
