const ModalTypes = {
  USER_FORM: "USER_FORM",
  LOG_FORM: "LOG_FORM",
  WORKPLACE_FORM: "WORKPLACE_FORM",
  CHECK_CODE: "CHECK_CODE",
  RESET_PASSWORD: "RESET_PASSWORD",
  DOCUMENT_TYPE_FORM: "DOCUMENT_TYPE_FORM",
  TICKET_DETAIL: "TICKET_DETAIL",
  TRANSLATE_FORM: "TRANSLATE_FORM",
  USER_DOCUMENTS_FORM: "USER_DOCUMENTS_FORM",
  GENERAL_DOCUMENTS_FORM: "GENERAL_DOCUMENTS_FORM",
  CHANGE_PASSWORD_FORM: "CHANGE_PASSWORD_FORM",
  CROP_FILE_MODAL: "CROP_FILE_MODAL",
  SUBCONTRACTOR_FORM: "SUBCONTRACTOR_FORM",
  CUSTOMER_FORM: "CUSTOMER_FORM",
  USER_DETAIL_FORM: "USER_DETAIL_FORM",
  WORKPLACE_DETAIL_FORM: "WORKPLACE_DETAIL_FORM",
}
export default ModalTypes;
