import React, { useState } from "react";
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import './crop.css'
import ModalFullWidthOverlay from "../../components/high-level/modal/modal-full-overlay";
import i18next from "i18next";

const CropFileModal = ({ nodeKey, image, onImageCropped, closeModal, onCancel }) => {
    const [formProps, setFormProps] = useState({
        image,
        crop: { x: 0, y: 0 },
        zoom: 1,
        aspect: 1,
        croppedAreaPixels: null,
    });
    const cropImage = async () => {
        const croppedImage = await getCroppedImg(
            formProps.image,
            formProps.croppedAreaPixels
        )
        if (onImageCropped instanceof Function)
            onImageCropped(croppedImage);
        closeModal();
    }

    const onCropChange = crop => setFormProps(curr => ({ ...curr, crop }))

    const onCropComplete = (croppedArea, croppedAreaPixels) => setFormProps(curr => ({ ...curr, croppedAreaPixels }))

    const onZoomChange = zoom => setFormProps(curr => ({ ...curr, zoom }))

    const handleCancel = () => {
        if (onCancel instanceof Function)
            onCancel();
        closeModal();
    }
    
    return (
        <ModalFullWidthOverlay nodeKey={nodeKey} isOpen={true} handleOk={cropImage} handleCancel={handleCancel}>
            <div className="crop-container">
                <Cropper
                    minZoom={1}
                    image={formProps.image}
                    crop={formProps.crop}
                    zoom={formProps.zoom}
                    aspect={formProps.aspect}
                    restrictPosition={true}
                    onCropChange={onCropChange}
                    onCropComplete={onCropComplete}
                    onZoomChange={onZoomChange}
                />
            </div>
            <div style={{marginTop: 10, display: "flex",justifyContent: "center"}}>
                    <label style={{marginRight: 10}}>{i18next.t("crop_file_modal.zoom")} </label>
                    <input
                        type="range"
                        min={1}
                        max={3}
                        step={0.1}
                        value={formProps.zoom}
                        onChange={(e) => onZoomChange(e.target.value)}
                    />
                </div>
        </ModalFullWidthOverlay>
    );
}

export default CropFileModal;