import { FileDoneOutlined, LoadingOutlined } from "@ant-design/icons";
import { Card, Empty, Form, Modal } from "antd";
import i18next from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Actions as ModalActions } from "../../../redux/modal/reducers";
import apiService from "../../../services/api/apiService";
import general from "../../../utils/general";
import ModalTypes from "../../../utils/modal-types";
import SVG_FILES from "../../../utils/svg";
import useRefState from "../../../utils/use-ref-state";
import GridPopconfirmAction from "../virtualized-grid/grid-popconfirm-action";
import { FormAction } from "./";
import ImageSlider from "./image-slider";
import "./style.scss";
const FormMultiFileUpload = ({
  fileName = "fileUrl",
  imageDirection = "row",
  maxFileCount = 5,
  value = "[]",
  bucket,
  readOnly,
  errorMessage,
  antdFormItemProps,
  onChange,
  label,
  onChangeLoading,
  accept,
  hideWhenNotFileUploadedOnReadOnlyMode,
  multiValue,
  onDeleteChange,
  fileType,
  cropFile,
  containerStyle = {},
  style = {},
}) => {
  const dispatch = useDispatch();
  const [files, filesRef, setFiles] = useRefState([]);
  const [fileSizes, fileSizesRef, setFileSizes] = useRefState([]);
  const [keyForInput, setKeyForInput] = useState(general.generateRandomString(10));
  const [imageViewerState, setImageViewerState] = useState({ modalOpen: false, url: "", urlList: [] });
  useEffect(() => {
    let _files = [];
    if (typeof value === "string") _files = JSON.parse(general.isNullOrEmpty(value) ? "[]" : value);
    else _files = value;
    setFiles(_files);
  }, [value]);

  const [loading, setLoading] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);

  const deleteFile = useCallback((index) => {
    const deletedItem = filesRef.current[index];
    if (onDeleteChange instanceof Function) onDeleteChange(deletedItem);
    else onChange(JSON.stringify([...filesRef.current.filter((x) => x[fileName] !== deletedItem[fileName])]));
  }, []);

  const showImage = useCallback((index) => {
    const file = filesRef.current[index];
    const urlList = filesRef.current.map((file) => file[fileName]).filter((url) => url); // Tüm[fileName]'leri al ve boş olmayanları filtrele
    let urlListt = [];
    if (multiValue) {
      const index = multiValue.findIndex((item) => item === urlList[0]);
      if (index !== -1) {
        multiValue = [...urlList, ...multiValue.slice(index + 1), ...multiValue.slice(0, index)];
      }
    } else {
      const index = urlList.findIndex((item) => item === file[fileName]);
      if (index !== -1) {
        urlListt = [file[fileName], ...urlList.slice(index + 1), ...urlList.slice(0, index)];
      }
    }
    setImageViewerState({ modalOpen: true, url: file?.[fileName], urlList: multiValue ? multiValue : urlListt });
  }, []);

  const inputRef = useRef(null);

  const onFileSelect = useCallback(async (e) => {
    const acceptableFiles = ["png", "jpg", "jpeg", "pdf", "docx", "doc", "xlsx", "xls", "csv"];
    let fileExtension = general.getFileExtension(e.target.files[0]?.name);
    if (!acceptableFiles.includes(fileExtension.toLowerCase())) {
      general.notificationWarning(i18next.t("validation.file_upload.file_format_not_supported"));
      return;
    }
    if (e.target?.files?.length > 0) {
      if (maxFileCount > 1) {
        for (let i = 0; i < e.target.files.length; i++) if (i < maxFileCount) await uploadFile(e.target.files[i]);
      } else await uploadFile(e.target.files[0]);
    }
    setKeyForInput(general.generateRandomString(10));
  }, []);

  const onClcikSelectFile = useCallback((e) => {
    e.preventDefault();
    if (inputRef.current) inputRef.current.click();
  }, []);

  const uploadFile = useCallback(async (file) => {
    if (cropFile) {
      var res = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          dispatch(
            ModalActions.openModal({
              type: ModalTypes.CROP_FILE_MODAL,
              modalProps: {
                image: e.currentTarget.result,
                onImageCropped: (image) => {
                  fetch(image)
                    .then((res) => res.arrayBuffer())
                    .catch(resolve)
                    .then((buf) => resolve(new File([buf], file.name, { type: file.type })))
                    .catch(resolve);
                },
                onCancel: () => resolve(null),
              },
            })
          );
        };
        reader.readAsDataURL(file);
      });

      if (!res) return;
      file = res;
    }

    //upload process
    const formData = new FormData();
    formData.append("file", file);
    formData.append("bucket", bucket);

    if (file.size > 10 * 1024 * 1024) {
      general.notificationError("validation.file_upload.file_size_too_large");
      setFiles((curr) => curr?.filter((x) => x.fileTempKey !== file?.fileTempKey));
      return;
    } else {
      setUploadPercent(0);
      setLoading(true);
      if (onChangeLoading instanceof Function) onChangeLoading(true);
      var uploadResult = await apiService.makeApiCall({
        controller: "fileupload",
        method: "post",
        axiosOptions: {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + global?.token,
            Accept: "application/json",
          },
          onUploadProgress: (progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadPercent(percentCompleted);
          },
          data: formData,
        },
      });
      setLoading(false);
      if (onChangeLoading instanceof Function) onChangeLoading(false);
      if (uploadResult.error) {
        general.notificationError(uploadResult.errorMessage);
        return;
      } else setFileSizes([...fileSizesRef.current, file.size]);

      if (onChange instanceof Function) onChange(JSON.stringify([...filesRef.current, ...uploadResult.data]));
    }
  }, []);

  if (readOnly && hideWhenNotFileUploadedOnReadOnlyMode && files.length == 0) return null;

  return (
    <div>
      <Modal visible={imageViewerState.modalOpen} footer={null} width={1000} title={i18next.t("general.file_viewer")} onCancel={() => setImageViewerState((curr) => ({ ...curr, modalOpen: false }))}>
        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img alt="example" class='image-modal-img' resiz src={imageViewerState.url} />
        </div> */}
        <div>
          <ImageSlider images={imageViewerState.urlList} />
        </div>
      </Modal>
      <Form.Item
        /*  label={label} */
        validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
        help={errorMessage}
        className="form-items"
        {...antdFormItemProps}
      >
        <Card
          size="default"
          // title={i18next.t(label)}
          className="border-style-upload"
          style={{ flex: 1, width: "100%", padding: " 10px 10px", borderRadius: 15, border: "2px solid rgba(233, 238, 243, 1)" }}
          bodyStyle={{ width: "100%" }}
        >
          <div style={{ display: "flex", flex: 1, flexDirection: imageDirection, flexWrap: "wrap" }}>
            {readOnly && files?.length === 0 && (
              <p style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}>
                <Empty description={i18next.t("general.no_files_found")} />
              </p>
            )}
            <div style={{ flexDirection: "row", display: "flex", gap: 20 }}>
              {files?.map((file, index) => {
                const extension = general.getFileExtension(file[fileName]);
                const isImage = general.isImageExtension(extension);

                return (
                  <div
                    className="imgContainer"
                    style={{
                      borderRadius: 15,
                      position: "relative",
                    }}
                    key={index}
                  >
                    {!(files?.length < maxFileCount && !readOnly) && <p style={{ position: "absolute", marginLeft: "-10px", top: -16, color: "rgba(160, 174, 192, 1)", fontWeight: 700, fontSize: 12 }}>{label?.toUpperCase()}</p>}

                    <div style={{ margin: "9px 0px 0px -12px", borderRadius: 12, borderWidth: 2, borderColor: "rgba(233, 238, 243, 1)", borderStyle: "solid", overflow: "hidden", ...containerStyle }}>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          if (isImage) showImage(index);
                          else window.location = file?.[fileName];
                        }}
                      >
                        {isImage && <img style={{ objectFit: "cover" }} src={file?.[fileName]} height={125} width={125} />}
                        {!isImage && (
                          <div style={{ display: "flex", alignItems: "center", width: 125, flexDirection: "column", height: 125, justifyContent: "center" }}>
                            <FileDoneOutlined />
                            <p style={{ marginBottom: 0 }}>
                              {extension} {i18next.t("general.file")}
                            </p>
                            {file.fileName != null && <p style={{ marginBottom: 0, textAlign: "center" }}>{file.fileName.length > 30 ? file.fileName.substring(0, 30) : file.fileName}</p>}
                          </div>
                        )}
                      </a>
                    </div>
                    <div
                      className="file-actions-buttons"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginRight: -15,
                          marginLeft: -11,
                        }}
                      >
                        {isImage && (
                          <FormAction
                            onClick={() => showImage(index)}
                            iconNode={
                              <svg width="29" height="29" viewBox="0 0 31 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <ellipse cx="15.2906" cy="16.6201" rx="15.1802" ry="16" fill="#EDB042" />
                                <path
                                  d="M15.2905 8.62012C18.0775 8.62012 20.5535 9.87712 22.3165 11.4331C23.2015 12.2141 23.9305 13.0911 24.4445 13.9641C24.9495 14.8211 25.2905 15.7501 25.2905 16.6201C25.2905 17.4901 24.9505 18.4191 24.4445 19.2761C23.9305 20.1491 23.2015 21.0261 22.3165 21.8071C20.5535 23.3631 18.0765 24.6201 15.2905 24.6201C12.5035 24.6201 10.0275 23.3631 8.26453 21.8071C7.37953 21.0261 6.65053 20.1491 6.13653 19.2761C5.63053 18.4191 5.29053 17.4901 5.29053 16.6201C5.29053 15.7501 5.63053 14.8211 6.13653 13.9641C6.65053 13.0911 7.37953 12.2141 8.26453 11.4331C10.0275 9.87712 12.5045 8.62012 15.2905 8.62012ZM15.2905 10.6201C13.1065 10.6201 11.0825 11.6131 9.58853 12.9321C8.84453 13.5881 8.25653 14.3051 7.85953 14.9791C7.45353 15.6691 7.29053 16.2401 7.29053 16.6201C7.29053 17.0001 7.45353 17.5711 7.85953 18.2611C8.25653 18.9351 8.84453 19.6511 9.58853 20.3081C11.0825 21.6271 13.1065 22.6201 15.2905 22.6201C17.4745 22.6201 19.4985 21.6271 20.9925 20.3081C21.7365 19.6511 22.3245 18.9351 22.7215 18.2611C23.1275 17.5711 23.2905 17.0001 23.2905 16.6201C23.2905 16.2401 23.1275 15.6691 22.7215 14.9791C22.3245 14.3051 21.7365 13.5891 20.9925 12.9321C19.4985 11.6131 17.4745 10.6201 15.2905 10.6201ZM15.2905 13.6201C15.3785 13.6201 15.4655 13.6241 15.5515 13.6311C15.3344 14.012 15.2485 14.4536 15.3069 14.8881C15.3653 15.3226 15.5648 15.7258 15.8748 16.0358C16.1848 16.3458 16.5881 16.5454 17.0225 16.6038C17.457 16.6622 17.8987 16.5762 18.2795 16.3591C18.3321 16.9613 18.2016 17.5653 17.905 18.092C17.6084 18.6187 17.1597 19.0435 16.6175 19.3108C16.0754 19.5781 15.4651 19.6754 14.8668 19.59C14.2684 19.5045 13.7097 19.2403 13.2641 18.8319C12.8184 18.4235 12.5066 17.8901 12.3693 17.3014C12.232 16.7127 12.2758 16.0963 12.4948 15.533C12.7139 14.9696 13.098 14.4855 13.5969 14.1442C14.0957 13.8029 14.6861 13.6202 15.2905 13.6201Z"
                                  fill="white"
                                />
                              </svg>
                            }
                          />
                        )}
                      </div>
                      <div
                        style={{
                          marginRight: -7,
                        }}
                      >
                        <FormAction
                          onClick={() => {
                            window.location = file?.[fileName];
                          }}
                          buttonType="success"
                          iconNode={
                            <svg width="29" height="29" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="16.1104" cy="16.6201" r="16" fill="#48BB78" />
                              <path
                                d="M16.4069 19.1276C16.2169 19.1276 16.0269 19.0576 15.8769 18.9076L13.3169 16.3476C13.0269 16.0576 13.0269 15.5776 13.3169 15.2876C13.6069 14.9976 14.0869 14.9976 14.3769 15.2876L16.4069 17.3176L18.4369 15.2876C18.7269 14.9976 19.2069 14.9976 19.4969 15.2876C19.7869 15.5776 19.7869 16.0576 19.4969 16.3476L16.9369 18.9076C16.7869 19.0576 16.5969 19.1276 16.4069 19.1276Z"
                                fill="white"
                              />
                              <path d="M16.4069 19.0575C15.9969 19.0575 15.6569 18.7175 15.6569 18.3075V8.13745C15.6569 7.72745 15.9969 7.38745 16.4069 7.38745C16.8169 7.38745 17.1569 7.72745 17.1569 8.13745V18.3075C17.1569 18.7175 16.8169 19.0575 16.4069 19.0575Z" fill="white" />
                              <rect x="10.8257" y="21.9097" width="1.56812" height="11.0942" rx="0.784058" transform="rotate(-90 10.8257 21.9097)" fill="white" />
                            </svg>
                          }
                        />
                      </div>
                      <div>
                        {!readOnly && (
                          <GridPopconfirmAction
                            actionProps={{
                              buttonType: "danger",
                              iconNode: (
                                <svg width="29" height="29" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <circle cx="16.1104" cy="16.6201" r="16" fill="#EA6E6A" />
                                  <path d="M8.11035 11.4502H24.1104" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M14.1104 15.4502V21.4502" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M18.1104 15.4502V21.4502" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M9.11035 11.4502L10.1104 23.4502C10.1104 24.5548 11.0058 25.4502 12.1104 25.4502H20.1104C21.2149 25.4502 22.1104 24.5548 22.1104 23.4502L23.1104 11.4502" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                  <path d="M13.1104 11.4502V8.4502C13.1104 7.89791 13.5581 7.4502 14.1104 7.4502H18.1104C18.6626 7.4502 19.1104 7.89791 19.1104 8.4502V11.4502" stroke="white" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              ),
                            }}
                            onClickYes={() => deleteFile(index)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {files?.length < maxFileCount && !readOnly && (
              <div>
                {[0].map((x) => (
                  <input accept={accept} key={keyForInput} max={maxFileCount} multiple={maxFileCount > 1} onSelect={onFileSelect} value={null} style={{ display: "none" }} ref={inputRef} type="file" onChange={onFileSelect} />
                ))}
                <p style={{ position: "absolute", marginLeft: "-10px", top: 14, color: "rgba(160, 174, 192, 1)", fontWeight: 700, fontSize: 12 }}>{label?.toUpperCase()}</p>

                <a href="#" onClick={onClcikSelectFile}>
                  <div
                    style={{
                      margin: "9px 0px -10px -12px",
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "#e4e9f0",
                      borderStyle: "solid",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      overflow: "hidden",
                      width: 125,
                      height: 125,
                      ...style,
                    }}
                  >
                    {loading ? <LoadingOutlined /> : SVG_FILES.DropFiles}
                    <p style={{ marginBottom: 0, fontWeight: "600", color: "black", fontSize: 18, marginTop: 10 }}>
                      {loading && (uploadPercent == 100 ? i18next.t("general.processing") : i18next.t("general.uploading").replace("[percent]", uploadPercent))}
                      {!loading && i18next.t("general.file_select")}
                    </p>
                  </div>
                </a>
              </div>
            )}
          </div>
        </Card>
      </Form.Item>
    </div>
  );
};

export default FormMultiFileUpload;
