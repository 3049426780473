import { Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Actions as ModalActions } from "../../../redux/modal/reducers";

const CustomModal = ({ title, children, isOpen, nodeKey, width = 500, handleOk, footer = null, wrapClassName }) => {
  const dispatch = useDispatch();
  const [isOpenHook, setIsOpenHook] = useState(isOpen);

  useEffect(() => {
    if (!isOpen) onCancel();
  }, [isOpen]);

  const onCancel = () => {
    setIsOpenHook(false);
    setTimeout(() => {
      dispatch(
        ModalActions.closeModal({
          nodeKey: nodeKey,
        })
      );
    }, 300);
  };

  return (
    <Modal wrapClassName={wrapClassName} title={title} animation="slideUp" open={isOpenHook} onCancel={onCancel} footer={footer} onOk={handleOk} centered style={{ top: 20 }} width={width}>
      <div>{children}</div>
    </Modal>
  );
};
export default CustomModal;
