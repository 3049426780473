import ModalFullWidthOverlay from "../../components/high-level/modal/modal-full-overlay";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { useMemo, useState } from "react";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import { Actions as ModalActions } from "../../redux/modal/reducers";
import ModalTypes from "../../utils/modal-types";
import general from "../../utils/general";
import FormInput from "../../components/high-level/form/form-input";
import Validator from "../../components/high-level/validator";
import FormInputCode from "../../components/high-level/form/form-input-code";
import OTPInput from "react-otp-input";
import FormInputPassword from "../../components/high-level/form/form-input-password";

const CheckCodeModal = ({ nodeKey, closeModal, email, onConfirm }) => {
  const [validator] = useState(new Validator());
  const validatorScopeKey = useMemo(() => validator.generateNewScopeKey(), []);
  const dispatch = useDispatch();
  const [formProps, setFormProps] = useState({
    email,
  });

  const onChange = (key) => (value) => setFormProps((curr) => ({ ...curr, [key]: value }));

  const checkCode = () => {
    if (validator.allValid()) {
      dispatch(
        ApiCallActions.NativePost({
          controller: "auth",
          action: "checkCodeForForgotPassword",
          data: formProps,
          showAlertOnError: true,
          showLoading: true,
          onSuccess: ({ data }) => {
            if (data) {
              dispatch(
                ModalActions.openModal({
                  type: ModalTypes.RESET_PASSWORD,
                  modalProps: {
                    ...formProps,
                    onConfirm: () => {
                      onConfirm();
                      closeModal();
                    },
                  },
                })
              );
            } else general.notificationError("general.error_occured");
          },
          onError: (x) => console.log("err", x),
        })
      );
    }
    setFormProps((curr) => ({ ...curr, reRender: Math.random() }));
  };

  return (
    <ModalFullWidthOverlay nodeKey={nodeKey} useLargeModal={false} width={400} padding="50" handleOk={checkCode} title={i18next.t("general.check_code")} handleCancel={closeModal}>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginLeft: 30, marginRight: 30, marginTop: 20, marginBottom: 30 }}>
        <OTPInput
          value={formProps.verificationCode}
          onChange={onChange("verificationCode")}
          numInputs={4}
          inputStyle={{
            border: "none",
            outline: "none",
            boxShadow: "none",
            height: "45px",
            fontSize: "25px",
            width: "45px",
            textAlign: "center",
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: "rgba(210, 216, 222, 1)",
            borderRadius: "8px",
            marginLeft: "15px",
          }}
          renderInput={(props) => <input {...props} />}
        />
      </div>
    </ModalFullWidthOverlay>
  );
};

export default CheckCodeModal;
