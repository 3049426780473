import React from "react";
import { useDispatch } from "react-redux";
import { Actions as ModalActions } from "../../../redux/modal/reducers";
import Rodal from "../rodal/rodal";

const ModalFullWidthContainer = ({ children, nodeKey, handleCancel, customStyles }) => {
  const dispatch = useDispatch();
  const close = () => {
    setTimeout(() => {
      dispatch(
        ModalActions.closeModal({
          nodeKey: nodeKey,
        })
      );
      if (handleCancel instanceof Function) handleCancel();
    }, 300);
  };

  return (
    <Rodal
      width={200}
      customStyles={{
        ...customStyles,
        marginTop: 0,
      }}
      animation="slideUp"
      visible={true}
      onClose={close}
    >
      <div style={{ padding: "2rem" }}>{children}</div>
    </Rodal>
  );
};

export default ModalFullWidthContainer;
