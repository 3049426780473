import axios from "axios";
import store from "store";
import AppConfig from "../../utils/config/app-config";
import general from "../../utils/general";

const exampleApiCall = {
  controller: "",
  action: "",
  query: {
    id: 1,
    val: 2,
  },
  itemId: 1, // for detail, delete, put actions
  method: "", // get, post, put, delete
  data: {}, // for, post, put json body data
  token: "", // bearer token
};

const makeApiCall = async (payload) => {
  try {
    let url = "";
    const sendData = general.isNullOrEmpty(payload?.data) ? null : { ...payload.data };
    url = `${AppConfig.API_URL}/${payload?.controller}`;

    if (!general.isNullOrEmpty(payload?.action)) url += `/${payload?.action}`;
    if (!general.isNullOrEmpty(payload?.itemId)) url += `/${payload?.itemId}`;
    if (payload?.query instanceof Object) {
      const keys = Object.keys(payload?.query);
      if (keys.length > 0) url += "?";

      for (let i = 0; i < keys.length; ++i) {
        url +=
          keys[i] +
          "=" +
          (payload?.query[keys[i]] instanceof Object || payload?.query[keys[i]] instanceof Array ? encodeURI(JSON.stringify(payload?.query[keys[i]])) : encodeURI(payload?.query[keys[i]]));
        if (i < keys.length - 1) url += "&";
      }
    }

    if (url.includes("/auth")) url = url.replace("/admin", "");

    let options = {
      url,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + payload.token,
      },
      method: payload.method,
      ...(sendData ? { data: sendData } : {}),
      ...payload?.axiosOptions,
    };
    const res = await axios(options);

    if (res.data?.status) {
      return {
        success: true,
        data: res.data?.data,
        pagination: res.data?.pagination,
      };
    } else {
      return {
        error: true,
        data: res.data?.data,
        errorMessage: res.data?.exceptionMessage,
        errorMessageTechnical: res.data?.exceptionMessageTechnical,
      };
    }
  } catch (error) {
    if (error?.response?.data?.logout == true || error?.response?.status == 401) {
      store.remove("token");
      window.location.reload();
    }
    if (!general.isNullOrEmpty(error?.response?.status)) {
      // error, but not network error. Have api error
      return {
        error: true,
        data: error?.response?.data?.data,
        errorMessage: error?.response?.data?.message,
        errorMessageTechnical: error?.response?.data?.message,
      };
    }
    return {
      error: true,
      data: null,
      errorMessage: "error.unknown_error",
      errorMessageTechnical: error?.message,
    };
  }
};

export default {
  makeApiCall,
};
