import { ConnectedRouter } from "connected-react-router";
import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import Layout from "./layouts";

const routes = [
  {
    path: "/dashboard/alpha",
    Component: lazy(() => import("./pages/dashboard/alpha")),
    exact: true,
  },
  {
    path: "/user",
    Component: lazy(() => import("./pages/user/index")),
    exact: true,
  },
  {
    path: "/workplace",
    Component: lazy(() => import("./pages/workplace/index")),
    exact: true,
  },
  {
    path: "/documenttype",
    Component: lazy(() => import("./pages/documenttype/index")),
    exact: true,
  },
  {
    path: "/user-documents",
    Component: lazy(() => import("./pages/user-documents/index")),
    exact: true,
  },
  {
    path: "/general-documents",
    Component: lazy(() => import("./pages/general-documents/index")),
    exact: true,
  },
  {
    path: "/tickets",
    Component: lazy(() => import("./pages/tickets/index")),
    exact: true,
  },
  {
    path: "/subcontractors",
    Component: lazy(() => import("./pages/subcontractors/index")),
    exact: true,
  },
  {
    path: "/screen-record-logs",
    Component: lazy(() => import("./pages/screen-record-logs/index")),
    exact: true,
  },
  {
    path: "/customer",
    Component: lazy(() => import("./pages/customer/index")),
    exact: true,
  },
  {
    path: "/working-time-list",
    Component: lazy(() => import("./pages/working-time-list/index")),
    exact: true,
  },
  {
    path: "/working-time-calendar",
    Component: lazy(() => import("./pages/working-time-calendar/index")),
    exact: true,
  },
  {
    path: "/report",
    Component: lazy(() => import("./pages/report/index")),
    exact: true,
  },
  {
    path: "/translate",
    Component: lazy(() => import("./pages/translate/index")),
    exact: true,
  },

  // Auth Pages
  {
    path: "/auth/login",
    Component: lazy(() => import("./pages/auth/login")),
    exact: true,
  },
  {
    path: "/auth/forgot-password",
    Component: lazy(() => import("./pages/auth/forgot-password")),
    exact: true,
  },
];

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings?.routerAnimation,
});

const Router = ({ history, routerAnimation }) => {
  return (
    <>
      <ConnectedRouter history={history}>
        <Layout history={history}>
          <Route
            render={(state) => {
              const { location } = state;
              return (
                <SwitchTransition>
                  {/* <CSSTransition key="test">
                  <p>asdasd</p>
                </CSSTransition> */}
                  <CSSTransition key={location?.pathname} appear classNames={routerAnimation} timeout={routerAnimation === "none" ? 0 : 300}>
                    <Switch location={location}>
                      <Route exact path="/" render={() => <Redirect to="/dashboard/alpha" />} />
                      {routes.map(({ path, Component, exact }) => (
                        // add params to path
                        <Route
                          path={path}
                          key={path}
                          exact={exact}
                          render={() => {
                            return (
                              <div className={routerAnimation}>
                                <Suspense fallback={null}>
                                  <Component />
                                </Suspense>
                              </div>
                            );
                          }}
                        />
                      ))}
                      <Redirect to="/auth/404" />
                    </Switch>
                  </CSSTransition>
                </SwitchTransition>
              );
            }}
          />
        </Layout>
      </ConnectedRouter>
    </>
  );
};

export default connect(mapStateToProps)(Router);
