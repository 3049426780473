import { UndoOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import FloatLabel from "../../low-level/float-label";
import React, { useCallback, useState } from "react";
import general from "../../../utils/general";

const FormInputPassword = ({
  label,
  placeholder = "form.select.placeholder",
  placeholderValue = "",
  value,
  errorMessage,
  onChange,
  prefix,
  suffix,
  antdFormItemProps,
  antdInputProps,

  disabled,
  addRequiredSign = false,
  addRequiredSignToLabel = false,
  className = "",
  generatePasswordActive = true,
}) => {
  const [generatePasswordClassActive, setGeneratePasswordClassActive] = useState(false);

  const onCahngeEvent = (e) => {
    if (onChange instanceof Function) onChange(e.target.value);
  };

  const toggleGeneratePasswordClass = useCallback(() => {
    setGeneratePasswordClassActive(true);
    setTimeout(() => {
      setGeneratePasswordClassActive(false);
    }, 1000);
  }, [generatePasswordClassActive]);

  const generatePassword = useCallback(() => {
    if (onChange instanceof Function) onChange(general.generateRandomString(10));
    toggleGeneratePasswordClass();
  });

  return (
    <Form.Item validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"} help={errorMessage ?? ""} {...antdFormItemProps}>
      <FloatLabel label={label + (addRequiredSignToLabel ? "*" : "")} name={label} value={value} disabled={false}>
        <Input.Password
          autoComplete="new-password"
          suffix={suffix}
          allowClear
          className={"border-style " + className}
          value={value}
          disabled={disabled}
          placeholder={placeholderValue}
          size="large"
          visibilityToggle
          onChange={onCahngeEvent}
          style={{ padding: generatePasswordActive ? "6.5px 30px 6.5px 11px" : "6.5px 10px 6.5px 11px" }}
          {...antdInputProps}
        />
        {generatePasswordActive && (
          <div onClick={generatePassword} className={generatePasswordClassActive ? "generate-password-icon" : ""} style={{ position: "absolute", top: 8, right: 12, fontSize: 15, cursor: "pointer" }}>
            <UndoOutlined />
          </div>
        )}
      </FloatLabel>
    </Form.Item>
  );
};

const areEqual = (prev, next) => {
  return prev.label === next.label && prev.placeholder === next.placeholder && prev.errorMessage === next.errorMessage && prev.disabled === next.disabled && prev.value == next.value;
};
export default React.memo(FormInputPassword, areEqual);
