import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormAction, FormContainer, FormMultiFileUpload, FormSelect } from "../../components/high-level/form";
import FormInput from "../../components/high-level/form/form-input.js";
import FormRangePicker from "../../components/high-level/form/form-range-picker.js";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container";
import Validator from "../../components/high-level/validator";
import CommandBus from "../../infrastructure/command-bus/command-bus.js";
import { CommandTypeEnum } from "../../infrastructure/command-bus/command-type-enum.ts";
import useUserType from "../../infrastructure/event-bus/hooks/use-user-type";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import { BucketEnum } from "../../utils/enums/bucket-enum.ts";
import FileTypeEnum from "../../utils/enums/file-type-enum.ts";
import StatusEnum from "../../utils/enums/status-enum";
import UserTypeEnum from "../../utils/enums/user-type-enum";
import general from "../../utils/general";
import useRefState from "../../utils/use-ref-state.js";

const UserDocumentsFormModal = ({ nodeKey, id, userName, closeModal }) => {
  const dispatch = useDispatch();
  const { isCustomer } = useUserType();
  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, formPropsRef, setFormProps] = useRefState([]);
  const [isUserExist, setIsUserExist] = useState(false);
  const [documentTypeList, setDocumentTypeList] = useState([]);
  const [user, setUser] = useState(userName ? { name: userName, id: id, value: id } : null);
  const updateFormProps = (values) => {
    setFormProps((curr) => {
      if (curr.length === 0) {
        return [values];
      }
      let lastIndex = -1;

      for (let i = curr.length - 1; i >= 0; i--) {
        if (!curr[i].empty && curr[i].documentTypeId === values.documentTypeId) {
          lastIndex = i;
          break;
        }
      }

      if (lastIndex !== -1) {
        return [...curr.slice(0, lastIndex + 1), values, ...curr.slice(lastIndex + 1)];
      } else {
        return [values, ...curr];
      }
    });
  };
  useEffect(() => {
    dispatch(
      ApiCallActions.List({
        controller: "documentType",
        action: "list",
        showLoading: true,
        showAlertOnError: true,
        callback: () => {
          setLoading(false);
        },
        onSuccess: async (documentRes) => {
          const sortedData = documentRes?.data?.sort((a, b) => a?.sequence - b?.sequence);
          setDocumentTypeList(sortedData);
          let _data = [];
          if (documentRes?.data?.length > 0) {
            documentRes?.data?.map((item) => {
              _data?.push({
                documentTypeId: item?.id,
                documentUrl: "",
                documentExtensionType: "",
                name: "",
                startDate: "",
                endDate: "",
                empty: true,
                userId: id,
              });
            });
            setFormProps(_data);
          }
          setLoading(true);
          if (id || user?.id) {
            dispatch(
              ApiCallActions.List({
                controller: "document",
                action: "listForUser",
                data: { userId: id ?? user?.id },
                showLoading: true,
                showAlertOnError: true,
                callback: () => setLoading(false),
                onSuccess: async ({ data }) => {
                  // if (general.isNullOrEmpty(id)) {
                  //   setIsUserExist(false);
                  //   setLoading(false);
                  //   return;
                  // } else {
                  setIsUserExist(data?.length > 0);
                  // }
                  setLoading(false);
                  if (data?.length === 0) return;
                  let _data = [];
                  if (data?.length > 0) {
                    data?.map((item) => {
                      _data.unshift({
                        documentTypeId: item?.documentTypeId,
                        documentUrl: item?.documentUrl,
                        documentExtensionType: item?.documentExtensionType,
                        name: item?.name,
                        startDate: item?.startDate,
                        endDate: item?.endDate,
                        empty: false,
                        userId: item?.userId,
                        id: item?.id,
                      });

                      if (_data?.filter((x) => x.documentTypeId === item?.documentTypeId && x.empty === true)?.length === 0 && !isCustomer) {
                        _data?.push({
                          documentTypeId: item?.documentTypeId,
                          documentUrl: "",
                          documentExtensionType: "",
                          name: "",
                          startDate: "",
                          endDate: "",
                          empty: true,
                          userId: item?.userId,
                        });
                      }
                    });
                    documentRes?.data?.map((item) => {
                      const isExist = _data?.filter((x) => x.documentTypeId === item?.id)?.length > 0;
                      if (!isExist) {
                        _data?.push({
                          documentTypeId: item?.id,
                          documentExtensionType: "",
                          documentUrl: "",
                          name: "",
                          startDate: "",
                          endDate: "",
                          empty: true,
                          userId: data[0]?.userId,
                        });
                      }
                    });
                    setFormProps(_data);
                  }
                },
              })
            );
          }
        },
      })
    );
  }, [user]);

  const save = useCallback(() => {
    const isValid = validator.allValid();
    reRender(1);
    if (isValid) {
      const formData = { documentList: formProps.filter((x) => !x.empty)?.map((item) => ({ ...item, userId: user?.id })), userId: user?.id };
      setLoading(true);
      if (general.isNullOrEmpty(id) && !isUserExist) {
        // create
        dispatch(
          ApiCallActions.Save({
            controller: "document",
            data: formData,
            showLoading: true,
            onSuccess: async ({ data }) => {
              CommandBus.sc.publish(CommandTypeEnum.VirtualizedListRefresh);
              closeModal?.();
              general.messageSuccess("general.save_success");
            },
            onError: (message) => general.notificationError(message.errorMessageTechnical),
            callback: () => setLoading(false),
          })
        );
      } else {
        // update
        dispatch(
          ApiCallActions.Update({
            controller: "document",
            action: "editForUser",
            // itemId: id,
            data: formData,
            onSuccess: async ({ data }) => {
              CommandBus.sc.publish(CommandTypeEnum.VirtualizedListRefresh);

              closeModal?.();
              general.messageSuccess("general.update_success");
            },
            onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
            callback: () => setLoading(false),
          })
        );
      }
    }
  }, [formProps]);

  const onChangeDocument = useCallback((val, documentTypeId) => {
    let parsedDocument = val;
    if (typeof val === "string") parsedDocument = JSON.parse(val);

    updateFormProps({
      documentUrl: parsedDocument[0]?.fileUrl,
      documentTypeId: documentTypeId,
      documentExtensionType: parsedDocument[0]?.fileType,
      userId: user?.id,
      name: "",
      startDate: "",
      endDate: "",
      empty: false,
    });
  }, []);
  const onDeleteChangeDocument = useCallback(
    (deletedItem) => {
      const temp = [...formPropsRef.current];
      const _temp = temp.filter((x) => deletedItem?.documentUrl !== x?.documentUrl);
      setFormProps(_temp);
    },
    [formPropsRef.current]
  );

  const onChangeName = useCallback(
    (val, id, index) => {
      const temp = [...formPropsRef.current];
      const _temp = temp.filter((x) => id === x?.documentTypeId);
      _temp[index].name = val;
      setFormProps(temp);
    },
    [formPropsRef]
  );

  const onChangeDate = useCallback(
    (val, id, index) => {
      const temp = [...formPropsRef.current];
      const _temp = temp.filter((x) => id === x?.documentTypeId);
      _temp[index].startDate = val?.[0];
      _temp[index].endDate = val?.[1];
      setFormProps(temp);
    },
    [formPropsRef]
  );

  const [fileLoading, setFileLoading] = useState(false);
  const onChangeFileLoading = useCallback((val) => setFileLoading(val), []);
  return (
    <ModalFullWidthContainer
      customStyles={{
        marginTop: "100px",
        borderRadius: "15px",
        background: "#f5f5f5",
      }}
      nodeKey={nodeKey}
    >
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        footerActions={
          isCustomer
            ? []
            : [
                {
                  show: true,
                  node: <FormAction customClassName={"save"} enableFunctionEqualityCheckForMemo loading={loading} text={i18next.t("form.button.save")} iconNode={<SaveOutlined />} buttonType="success" onClick={save} />,
                },
              ]
        }
        headerTitle={isCustomer ? i18next.t("user_documents.form_title.see") : !isUserExist ? i18next.t("user_documents.form_title.add") : i18next.t("user_documents.form_title.edit")}
      >
        <div style={{ backgroundColor: "white", borderRadius: "15px", padding: "2px 20px", marginTop: 10, position: "relative" }} className="col-12">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3" style={{ borderRadius: "15px" }}>
            <FormSelect
              addRequiredSign
              className="user"
              controller="user"
              isUiFilter={true}
              action={"dropdown"}
              label={i18next.t("user_documents.form.user")}
              value={user}
              disabled={id ? isUserExist : false}
              selectedId={user?.id}
              selectedName={user?.name}
              onChange={setUser}
              filter={{ userType: UserTypeEnum.Employee, status: StatusEnum.Active }}
              errorMessage={validator.register("user", user, [{ rule: "required" }], validatorScopeKey)}
            />
          </div>
        </div>
        {documentTypeList?.map((item, index) => {
          return (
            <>
              <div style={{ backgroundColor: "white", borderRadius: "15px", padding: "25px 20px", marginTop: 10, position: "relative" }} className="col-12">
                <div
                  style={{
                    position: "absolute",
                    top: "15px",
                    left: "50px",
                    fontWeight: 700,
                    color: "rgba(160, 174, 192, 1)",
                    fontSize: "14px",
                    zIndex: 1,
                  }}
                >
                  {item?.name?.toUpperCase()}
                </div>
                <fieldset>
                  <div className="row">
                    {formProps
                      .filter((x) => x?.documentTypeId === item?.id)
                      .map((document, index) => {
                        return (
                          <>
                            <div className="col-12">
                              <FormMultiFileUpload
                                fileName={"documentUrl"}
                                accept={".png,.jpg,.jpeg,.pdf,.docx,.doc,.xlsx,.xls,.csv"}
                                maxFileCount={1}
                                imageDirection={"row"}
                                onDeleteChange={(deletedItem) => onDeleteChangeDocument(deletedItem)}
                                readOnly={isCustomer}
                                // multiValue
                                bucket={BucketEnum.UserDocument}
                                fileType={FileTypeEnum.UserDocument}
                                value={general.isNullOrEmpty(document?.documentUrl) ? [] : [document]}
                                onChangeLoading={onChangeFileLoading}
                                onChange={(e) => onChangeDocument(e, item?.id)}
                              />
                            </div>
                            <div
                              style={{
                                marginTop: "-10px",
                              }}
                              className="col-12"
                            >
                              {document?.documentUrl && (
                                <div>
                                  <div
                                    style={{}}
                                    className="  row
                                    border-top                   
                                    py-4

                                    "
                                  >
                                    <div className="col-6">
                                      <FormInput
                                        addRequiredSign
                                        disabled={isCustomer}
                                        className="name"
                                        label={i18next.t("user_documents.form.name")}
                                        placeholder={i18next.t("user_documents.form.name")}
                                        value={document?.name}
                                        maxLength={50}
                                        onChange={(val) => onChangeName(val, item?.id, index)}
                                        errorMessage={validator.register("name_" + document.documentTypeId + index, document?.name, [{ rule: "required" }], validatorScopeKey)}
                                      />
                                    </div>
                                    <div className="col-6">
                                      <FormRangePicker
                                        disabled={isCustomer}
                                        addRequiredSign
                                        label={i18next.t("user_documents.form.date")}
                                        value={document?.startDate && document?.endDate && [dayjs(document?.startDate), dayjs(document?.endDate)]}
                                        onChange={(val) => onChangeDate(val, item?.id, index)}
                                        errorMessage={validator.register("date_" + document?.documentTypeId + index, document?.endDate, [{ rule: "required" }], validatorScopeKey)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        );
                      })}
                  </div>
                </fieldset>
              </div>
            </>
          );
        })}
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default UserDocumentsFormModal;
