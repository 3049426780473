import { Layout, Menu } from "antd";
import classNames from "classnames";
import i18next from "i18next";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import getMenuData from "../../../../../services/menu";
import store from "store";
import style from "./style.module.scss";

const mapStateToProps = ({ menu, settings, user }) => {
  return {
    menuData: menu.menuData,
    isMenuCollapsed: settings.isMenuCollapsed,
    isMobileView: settings.isMobileView,
    isMenuUnfixed: settings.isMenuUnfixed,
    isMenuShadow: settings.isMenuShadow,
    leftMenuWidth: settings.leftMenuWidth,
    menuColor: settings.menuColor,
    logo: settings.logo,
    role: user.role,
  };
};

const MenuLeft = ({ dispatch, menuData = [], location: { pathname }, isMenuCollapsed, isMobileView, isMenuUnfixed, isMenuShadow, leftMenuWidth, menuColor, logo, role }) => {
  useEffect(() => {
    const menuData = getMenuData();
    dispatch({
      type: "menu/SET_STATE",
      payload: {
        menuData,
      },
    });
  }, []);
  const [selectedKeys, setSelectedKeys] = useState(store.get("app.menu.selectedKeys") || []);
  const [openedKeys, setOpenedKeys] = useState(!isMenuCollapsed && (store.get("app.menu.openedKeys") || []));

  useEffect(() => {
    applySelectedKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menuData]);

  const applySelectedKeys = () => {
    const flattenItems = (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []);

    var selectedItem = find(flattenItems(menuData, "children"), ["url", pathname]);

    if (pathname.toString().includes("/device/detail/")) {
      selectedItem = { title: "left_menu.device_list", key: "device-list", url: "/device/list" };
    }
    setSelectedKeys(selectedItem ? [selectedItem.key] : []);
  };

  const onCollapse = (value, type) => {
    if (type === "responsive" && isMenuCollapsed) {
      return;
    }
    dispatch({
      type: "settings/CHANGE_SETTING",
      payload: {
        setting: "isMenuCollapsed",
        value: !isMenuCollapsed,
      },
    });
    setOpenedKeys([]);
  };

  const onOpenChange = (keys) => {
    store.set("app.menu.openedKeys", keys);
    setOpenedKeys(keys);
  };

  const handleClick = (e) => {
    store.set("app.menu.selectedKeys", [e.key]);
    setSelectedKeys([e.key]);
  };

  const toggleMobileMenu = () => {
    dispatch({
      type: "settings/CHANGE_SETTING",
      payload: {
        setting: "isMobileMenuOpen",
        value: false,
      },
    });
  };
  useEffect(() => {
    if (pathname.includes("working-time-list")) {
      setSelectedKeys(["working-time-calendar"]);
    }
  }, [pathname]);
  const generateMenuItems = () => {
    const generateItem = (item) => {
      const { key, title, url, icon, disabled, count } = item;
      if (item.category) {
        return <Menu.ItemGroup key={Math.random()} title={i18next.t(item.title)} />;
      }
      if (item.url) {
        return (
          <Menu.Item key={key} disabled={disabled}>
            {item.target && (
              <a href={url} target={item.target} rel="noopener noreferrer">
                {count && <span className="badge badge-success ml-2">{count}</span>}
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
                <span className={style.title}>{title}</span>
              </a>
            )}
            {!item.target && (
              <>
                {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
                <Link to={url} onClick={toggleMobileMenu}>
                  {count && <span className="badge badge-success ml-2">{count}</span>}
                  <span
                    className={style.title}
                    style={{
                      color: key === selectedKeys[0] && "#E30613",
                      fontSize: 18,
                      fontWeight: "500",
                      marginLeft: 10,
                    }}
                  >
                    {i18next.t(title)}
                  </span>
                </Link>
              </>
            )}
          </Menu.Item>
        );
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {count && <span className="badge badge-success ml-2">{count}</span>}
          {icon && <span className={`${icon} ${style.icon} icon-collapsed-hidden`} />}
          <span className={style.title}>{title}</span>
        </Menu.Item>
      );
    };

    const generateSubmenu = (items) =>
      items.map((menuItem) => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
              {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
              <span className={style.title}>{i18next.t(menuItem.title)}</span>
            </span>
          );
          return (
            <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </Menu.SubMenu>
          );
        }
        return generateItem(menuItem);
      });
    return menuData.map((menuItem) => {
      if (menuItem.roles && !menuItem.roles.includes(role)) {
        return null;
      }
      if (menuItem.children) {
        const subMenuTitle = (
          <span key={menuItem.key}>
            {menuItem.icon && <span className={`${menuItem.icon} ${style.icon}`} />}
            <span className={style.title}>{i18next.t(menuItem.title)}</span>
            {menuItem.count && <span className="badge badge-success ml-2">{menuItem.count}</span>}
          </span>
        );
        return (
          <Menu.SubMenu title={subMenuTitle} key={menuItem.key}>
            {generateSubmenu(menuItem.children)}
          </Menu.SubMenu>
        );
      }
      return generateItem(menuItem);
    });
  };

  const menuSettings = isMobileView
    ? {
        width: leftMenuWidth,
        collapsible: false,
        collapsed: false,
        onCollapse,
      }
    : {
        width: leftMenuWidth,
        collapsible: true,
        collapsed: isMenuCollapsed,
        onCollapse,
        breakpoint: "lg",
      };

  return (
    <Layout.Sider
      style={{
        paddingTop: 64,
      }}
      {...menuSettings}
      className={`sidebar-menu ${classNames(`${style.menu}`, {
        [style.white]: menuColor === "white",
        [style.gray]: menuColor === "gray",
        [style.dark]: menuColor === "dark",
        [style.unfixed]: isMenuUnfixed,
        [style.shadow]: isMenuShadow,
      })}`}
    >
      <div
        className={style.menuOuter}
        style={{
          width: isMenuCollapsed && !isMobileView ? 80 : leftMenuWidth,
          height: isMobileView || isMenuUnfixed ? "calc(100% - 64px)" : "calc(100% - 60px)",
        }}
      >
        <PerfectScrollbar className="left-menu-content">
          <Menu onClick={handleClick} selectedKeys={selectedKeys} openKeys={openedKeys} onOpenChange={onOpenChange} mode="inline" className={style.navigation} inlineIndent="15">
            {generateMenuItems()}
          </Menu>
        </PerfectScrollbar>
      </div>
    </Layout.Sider>
  );
};

export default withRouter(connect(mapStateToProps)(MenuLeft));
