import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './global.scss' // app & third-party component styles
import 'react-base-table/styles.css'
import reportWebVitals from './reportWebVitals.js';
import { createHashHistory } from 'history';
import createSagaMiddleware from 'redux-saga'
import { routerMiddleware } from 'connected-react-router';
import { createStore, compose, applyMiddleware } from 'redux';

import { Provider } from 'react-redux'
import LoadApp from './load-app/index.js';


import reducers from './redux/reducers.js'
import sagas from './redux/sagas.js'
import Router from './router.js'

const history = createHashHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware]

const store = createStore(reducers(history), compose(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <LoadApp />
      <Router history={history} />
    </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export { store, history }
