import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Actions as ModalActions } from "../../../redux/modal/reducers";
import { Modal } from "antd";
import i18next from "i18next";

const ModalFullWidthOverlay = ({ children, useLargeModal = true, padding = "2rem", nodeKey, title, handleOk, handleCancel, width = undefined }) => {
  const dispatch = useDispatch();
  const close = () => {
    setTimeout(() => {
      dispatch(
        ModalActions.closeModal({
          nodeKey: nodeKey,
        })
      );
      if (handleCancel instanceof Function) handleCancel();
    }, 300);
  };

  return (
    <Modal
      title={i18next.t(title)}
      animation="slideUp"
      visible={true}
      onCancel={close}
      width={useLargeModal ? "100%" : width}
      onOk={handleOk}
      styles={{
        footer: {
          padding: "10px 20px",
          display: "flex",
          justifyContent: "center",
          borderTop: "none",
          backgroundColor: "white",
          border: "none",
        },
        //cancel button
        close: {
          display: "none",
        },
      }}
    >
      <div style={{ padding: padding, paddingTop: 10 }}>{children}</div>
    </Modal>
  );
};

export default ModalFullWidthOverlay;
