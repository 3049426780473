import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FloatLabel from "../../low-level/float-label";
import React, { useEffect, useRef, useState } from "react";
import general from "../../../utils/general";
import useRefState from "../../../utils/use-ref-state";
import i18next from "i18next";

const FormInput = ({
  label,
  placeholder = "",
  value,
  errorMessage,
  onChange,
  onFocus,
  onBlur,
  onClick,
  prefix,
  suffix,
  antdFormItemProps,
  antdInputProps,
  rightAction,
  multiLine = false,
  rows = 15,
  cols = 1,
  addRequiredSign = false,
  disabled,
  readOnly,
  onKeyPress,
  className = "",
  useNumberFormat = false,
  usePriceFormat = false,
  forceLowerCase = false,
  forceUpperCase = false,
  helperText,
  maxLength,
  minLength,
  keyboardType = "text",
  allowClear = true,
  inputRef = null,
  placeholderValue = "",
  useFormattedValue = true,
  autoSize = false,
  style,
}) => {
  const [formattedValue, formattedValueRef, setFormattedValue] = useRefState(value);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    onFocusEvent();
  }, []);

  useEffect(() => {
    if ((!isLoaded && value) || formattedValueRef.current != value) {
      if (usePriceFormat) {
        var val = value?.toString().replaceAll(".", ",");
        setFormattedValue(general.formatPrice(val, true));
      } else if (useNumberFormat) {
        setFormattedValue(general.formatNumber(value));
      } else {
        setFormattedValue(value);
      }
      setIsLoaded(true);
    }
  }, [value, isLoaded]);

  const onChangeEvent = (e) => {
    var val = e.target.value;
    if (useNumberFormat) {
      setFormattedValue(general.formatNumber(val));
      val = val.replaceAll(".", "");
    } else if (usePriceFormat) {
      setFormattedValue(general.formatPrice(val, true));
      val = val.toString().replaceAll("₺", "").replaceAll(".", "").replaceAll(",", ".");
    } else {
      setFormattedValue(val);
    }

    if (forceLowerCase) val = general.forceLowerCase(val);

    if (forceUpperCase) val = general.forceUpperCase(val);

    if (onChange instanceof Function) onChange(val);
  };
  const onClickEvent = (e) => {
    if (onClick instanceof Function) onClick(e.target.value);
  };
  const onFocusEvent = () => {
    if (onFocus instanceof Function) onFocus();

    if (usePriceFormat) {
      setFormattedValue(general.formatPrice(value?.toString().replaceAll(".", ","), true));
    }
  };
  const onBlurEvent = () => {
    if (onBlur instanceof Function) onBlur();

    if (usePriceFormat) {
      setFormattedValue(general.formatPrice(value?.toString().replaceAll(".", ",")));
    }
  };

  return (
    <Form.Item
      style={{
        border: "none",
        borderRadius: "8px",
      }}
      /*  label={general.isNullOrEmpty(label) ? null : label + (addRequiredSign ? " *" : "")} */
      validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
      help={errorMessage ? errorMessage : helperText ?? helperText}
      {...antdFormItemProps}
    >
      {!multiLine && (
        <FloatLabel addRequiredSign={addRequiredSign} label={label} name={label} value={value} disabled={disabled}>
          <Input
            style={{
              ...style,
            }}
            ref={inputRef}
            maxLength={maxLength}
            autoComplete="new-password"
            suffix={prefix}
            allowClear={false}
            className={"border-style " + className}
            onKeyPress={onKeyPress}
            value={useFormattedValue === true ? formattedValue : !general.isNullOrEmpty(value) ? value : ""}
            disabled={disabled}
            readOnly={readOnly}
            size="large"
            onChange={onChangeEvent}
            onClick={onClickEvent}
            onFocus={onFocusEvent}
            onBlur={onBlurEvent}
            type={keyboardType}
            placeholder={placeholderValue ? i18next.t(placeholderValue) + (addRequiredSign ? "*" : "") : ""}
            {...antdInputProps}
          />
        </FloatLabel>
      )}

      {multiLine && (
        <FloatLabel addRequiredSign={addRequiredSign} label={label} name={label} value={value} disabled={disabled}>
          <TextArea
            style={{ ...style, paddingRight: prefix ? "60px" : undefined }}
            ref={inputRef}
            prefix={prefix}
            allowClear={allowClear}
            className={"border-style " + className}
            onKeyPress={onKeyPress}
            value={value}
            autoSize={autoSize && { minRows: 1, maxRows: rows }}
            // rows={rows}
            // cols={cols}
            size="large"
            placeholder={placeholderValue ? i18next.t(placeholderValue) + (addRequiredSign ? "*" : "") : ""}
            disabled={disabled}
            readOnly={readOnly}
            onChange={onChangeEvent}
            onClick={onClickEvent}
            onFocus={onFocusEvent}
            onBlur={onBlurEvent}
            {...antdInputProps}
          />
          {prefix && <div style={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>{prefix}</div>}
        </FloatLabel>
      )}
      {rightAction}
    </Form.Item>
  );
};

const areEqual = (prev, next) => {
  return prev.label === next.label && prev.useFormattedValue === next.useFormattedValue && prev.placeholderValue === next.placeholderValue && prev.errorMessage === next.errorMessage && prev.disabled === next.disabled && prev.value == next.value;
};
export default React.memo(FormInput, areEqual);
