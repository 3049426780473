import { message, notification } from "antd";
import i18next from "i18next";
import moment from "moment";

const UNIT_CONVERSIONS = {
  KG_TO_POUND: 2.2046226218,
  FOOT_TO_CM: 30.48,
  INCH_TO_CH: 2.54,
};

const isNullOrEmpty = (value) => value === "" || value === null || value === undefined || value === "undefined" || (value instanceof Array && value?.length === 0) || (value instanceof Object && Object.keys(value).length === 0);
const isNullOrEmptySetValue = (value) => {
  if (value === undefined || value === "" || value === null || value === NaN) {
    return "";
  }
  return value;
};

const apiDateFormatToUserFormat = (date, showTime) => {
  if (isNullOrEmpty(date)) return null;
  if (isNaN(Date.parse(date))) return null;
  var parsedDate = new Date(date);
  if (parsedDate === NaN) return null;
  if (showTime) return moment(date).format("DD.MM.YYYY HH:mm");
  else return moment(date).format("DD.MM.YYYY");
};

const apiDateFormatToUserTimeFormat = (date) => {
  if (isNullOrEmpty(date)) return null;
  if (isNaN(Date.parse(date))) return null;
  var parsedDate = new Date(date);
  if (parsedDate === NaN) return null;
  else return moment(date).format("HH:mm");
};

const FootAndInchToCm = (foot, inch) => {
  if (inch > 11.9) {
    var addFoots = inch / 12;
    if (!(inch % 12)) {
      inch = 0;
    } else {
      inch = inch % 12;
    }
    foot = foot + parseInt(addFoots.toFixed(0), 10);
  }

  var cm = foot * UNIT_CONVERSIONS.FOOT_TO_CM;
  cm += inch * UNIT_CONVERSIONS.INCH_TO_CH;

  return cm.toFixed(2);
};

const cmToFootAndInch = (cm) => {
  var approxFoot = cm / UNIT_CONVERSIONS.FOOT_TO_CM;
  var decimals = approxFoot % 1;
  var inch = Math.round(decimals * 12);
  var foot = parseFloat(cm) < UNIT_CONVERSIONS.FOOT_TO_CM ? 0 : Math.floor(approxFoot);

  return {
    foot: foot,
    inch: inch,
  };
};
const kgToPound = (kg) => {
  return (kg * UNIT_CONVERSIONS.KG_TO_POUND).toFixed(1);
};

const poundToKg = (pound) => {
  return (pound / UNIT_CONVERSIONS.KG_TO_POUND).toFixed(1);
};

const isNumber = (value) => matchRegexp(value, /^\d+$/);

const isFloat = (value) => {
  const isFloatx = matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\,)?$/i) || matchRegexp(value, /^(?:-?[1-9]\d*|-?0)?(?:\,\d+)?$/i);
  return isFloatx;
};

const matchRegexp = (value, regexp) => {
  const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp);
  return validationRegexp.test(value);
};

const moneyInputRules = [{ rule: "isFloat" }, { rule: "required" }, { rule: "maxNumber", value: 99999999 }];

function removeEmojis(string) {
  var regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return string.toString().replace(regex, "");
}

const PhoneRegex = /\d{1}((\(\d{3}\) ?)|(\d{3})\s) ?\d{3} \d{2} \d{2}$/g;
const PhoneMask = "1111111111";
const IdentityNoMask = "11111111111";

const notificationSuccess = (message) => {
  notification.success({
    message: i18next.t("general.success"),
    description: i18next.t(message),
  });
};
const notificationError = (message) => {
  notification.error({
    message: i18next.t("general.error"),
    description: i18next.t(message),
  });
};
const notificationWarning = (message) => {
  notification.warning({
    message: i18next.t("general.warning"),
    description: i18next.t(message),
  });
};

const messageSuccess = (val) => {
  message.success(i18next.t(val));
};
const messageError = (val) => {
  message.error(i18next.t(val));
};
const messageWarning = (val) => {
  message.warning(i18next.t(val));
};

const notificationWarningCustomText = (message) => {
  notification.warning({
    message: i18next.t("general.warning"),
    description: message,
  });
};

const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

const generateRandomString = (length = 15) => {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

const generateKeyForUpdateGridItem = (id) => {
  return { id, key: generateRandomString(10) };
};

const addDaysToDate = (date, day) => {
  date.setDate(date.getDate() + day);
  return date;
};

const getFileExtension = (fileName) => fileName.substr(fileName.lastIndexOf(".") + 1)?.toLowerCase();

const isImageExtension = (extension) => ["jpg", "jpeg", "png"].includes(extension?.toLowerCase());

const hasAccess = (action) => {
  if (global.roles !== null) return global?.roles?.has(action);
  else return false;
};

const toUpper = (text) => text?.toLocaleUpperCase("tr-TR");

const getFirstFile = (filesJson) => {
  if (isNullOrEmpty(filesJson)) return null;
  const files = JSON.parse(filesJson);
  return files[0];
};
const getCellRendererClassName = (rowData) => {
  return "rowStyle rowStyle" + rowData?.gridRowStyle;
};
const calculateDistance = (lat1, lon1, lat2, lon2, unit) => {
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") {
      dist = dist * 1.609344;
    }
    if (unit == "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }
};

const formatNumberWithComma = (val) => {
  // const formattedPhoneNumber = general.formatPhoneNumber(val)
  if (val.length > 1) {
    val = val.startsWith("0") ? val.substring(1) : val;
    // space replace
    val = val.replace(/\s/g, "");
    // () replace
    val = val.replace(/\(/g, "");
    val = val.replace(/\)/g, "");
    val = val.replace(/\-/g, "");
    // number witoout replace
    val = val.replace(/\D/g, "");
  }
  return val;
};

const formatPhoneNumber = (value) => {
  // Eğer giriş değeri yoksa veya girilen değer "(___) ___ __ __" ise boş dize döndür
  if (!value || value === "(___) ___ __ __") return "";

  // Giriş değerinden tüm rakam dışındaki karakterleri kaldır
  const phoneNumber = value.replace(/[^\d]/g, "");

  // Telefon numarasının uzunluğunu kontrol etmek için
  const phoneNumberLength = phoneNumber.length;

  // Telefon numarasının uzunluğuna göre biçimlendirme yap
  if (phoneNumberLength < 3) {
    return phoneNumber;
  } else if (phoneNumberLength < 6) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else if (phoneNumberLength < 8) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6)}`;
  } else {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 8)} ${phoneNumber.slice(8)}`;
  }
};
const scrollTopForValidation = (validator) => {
  // This function is scrolling to the wrong position with the help of the classes assigned to the components before, according to the wrong contents coming with the validator.
  var list = [];

  // Ger invalid fields from the validator
  const classNameList = validator?.getInvalidItems();

  // Get the elements from the classNameList
  for (let i = 0; i < classNameList.length; i++) {
    // Get the element from the classNameList
    const items = document.getElementsByClassName(classNameList[i]);

    // Push the elements to the scroll list
    for (let j = 0; j < items.length; j++)
      //  get scroll top of the element and push it to the list
      list.push(items[j]?.getBoundingClientRect()?.top);
  }

  if (list.length > 0) {
    // Get the highest value from the list
    var topItemPosition = list.reduce((a, b) => {
      return Math.min(a, b);
    });

    // Scroll to the highest value
    window.scrollTo(0, window.pageYOffset - 100 + topItemPosition);
  }
};
const formatNumber = (number) => {
  var newNumber = "",
    frac = "";
  if (isNullOrEmpty(number)) return newNumber;

  var splittedNumber = number.toString().split(",");
  if (splittedNumber.length > 1) {
    frac = splittedNumber[1];
  }

  var isNegative = number.toString().indexOf("-") > -1;

  removeFormatNumber(number.toString().replace("-", "").split(",")[0])
    .split("")
    .reverse()
    .forEach((x, i) => {
      if (i % 3 === 0 && i !== 0) newNumber += "." + x;
      else newNumber += x;
    });

  return (isNegative ? "-" : "") + newNumber.split("").reverse().join("") + (splittedNumber.length > 1 ? "," + frac : "");
};

// const formatPrice = (price) => {
//     var val = formatNumber(price);

//     if (val != "") {
//         var splittedNumber = val.toString().split(",");
//         if (splittedNumber.length > 1) {
//             var frac = splittedNumber[1];
//             if (frac == "") {
//                 val = val.substring(0, val.length - 1);
//             }
//         }
//     }

//     return val + (val.length > 0 ? "₺" : "");
// }

const formatNickname = (loginId) => {
  const regExp = /[^a-zA-Z]+/g;
  let val = loginId.replaceAll(" ", "")?.trim().toString().toLowerCase().replace(regExp, "");
  return val;
};

const formatPrice = (price, removePriceIcon) => {
  if (!price) return "";
  price = price.toString();

  if (removePriceIcon) price = price.replaceAll("₺", "");

  let frac = "-";

  price = price.replaceAll(".", "");

  if (price.indexOf(",") > -1) {
    var splittedPrice = price.split(",");
    if (splittedPrice.length > 1) {
      frac = splittedPrice[1];
      price = splittedPrice[0];
    }
  }

  let newPrice = "";
  price
    .split("")
    .reverse()
    .forEach((x, i) => {
      if (i % 3 === 0 && i !== 0) newPrice += "." + x;
      else newPrice += x;
    });
  newPrice = newPrice.split("").reverse().join("");

  if (frac != "-") newPrice += "," + frac;

  if (!removePriceIcon) {
    //useDecimal price format 10.555,99
    // if (newPrice.indexOf(",") > -1 && newPrice.split(",")[1] == "") {
    //     newPrice = newPrice.substring(0, newPrice.length - 1);
    // }
    newPrice = newPrice + "₺";
  }

  return newPrice;
};

const removeFormatNumber = (number) => {
  return number?.toString()?.replace(/\./g, "");
};

const formatPriceFunc = (price) => {
  var currency_symbol = "₺";
  var formattedOutput = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    minimumFractionDigits: 2,
  });
  return formattedOutput.format(price).replace(currency_symbol, "");
};
const turkishToEnglishChars = (value) => {
  let val = value
    .replaceAll("Ğ", "g")
    .replaceAll("Ü", "u")
    .replaceAll("U", "u")
    .replaceAll("Ş", "s")
    .replaceAll("S", "s")
    .replaceAll("I", "i")
    .replaceAll("İ", "i")
    .replaceAll("Ö", "o")
    .replaceAll("Ç", "c")
    .replaceAll("ğ", "g")
    .replaceAll("ü", "u")
    .replaceAll("ş", "s")
    .replaceAll("ı", "i")
    .replaceAll("ö", "o")
    .replaceAll("ç", "c");
  return val;
};
const inputForToUpperChars = (value) => {
  let val = value.replaceAll("ü", "Ü").replaceAll("ş", "Ş").replaceAll("i", "İ").replaceAll("ğ", "Ğ").replaceAll("ö", "Ö").replaceAll("ç", "Ç");
  return val;
};

const forceLowerCase = (value) => {
  return value.replaceAll("İ", "i").replaceAll("I", "ı").toLowerCase();
};
const forceUpperCase = (value) => {
  return inputForToUpperChars(value).toUpperCase();
};

const wait = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

function convertToUrlFormat(text) {
  // Boşlukları '-' ile değiştir
  let urlSlug = text.trim().toLowerCase().replace(/\s+/g, "-");

  // URL'de izin verilmeyen karakterleri çıkar
  urlSlug = urlSlug.replace(/[^a-z0-9\-]/g, "");

  return urlSlug;
}

const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  if (hours > 0 && remainingMinutes > 0) {
    return `${hours}h ${remainingMinutes}m`;
  } else if (hours > 0 && remainingMinutes === 0) {
    return `${hours}h`;
  } else if (hours === 0 && remainingMinutes > 0) {
    return `${remainingMinutes}m`;
  } else {
    return "0m";
  }
};

export default {
  formatNumberWithComma,
  convertToUrlFormat,
  hasAccess,
  isImageExtension,
  getFileExtension,
  isNullOrEmpty,
  addDaysToDate,
  apiDateFormatToUserFormat,
  FootAndInchToCm,
  messageWarning,
  messageError,
  messageSuccess,
  generateRandomString,
  generateKeyForUpdateGridItem,
  notificationError,
  formatPhoneNumber,
  PhoneMask,
  IdentityNoMask,
  notificationSuccess,
  notificationWarning,
  PhoneRegex,
  cmToFootAndInch,
  isNumber,
  isFloat,
  kgToPound,
  poundToKg,
  matchRegexp,
  wait,
  removeEmojis,
  arrayMove,
  moneyInputRules,
  toUpper,
  getFirstFile,
  getCellRendererClassName,
  calculateDistance,
  scrollTopForValidation,
  formatNumber,
  removeFormatNumber,
  formatPrice,
  formatNickname,
  notificationWarningCustomText,
  formatPriceFunc,
  turkishToEnglishChars,
  forceLowerCase,
  forceUpperCase,
  apiDateFormatToUserTimeFormat,
  isNullOrEmptySetValue,
  inputForToUpperChars,
  formatTime,
};
