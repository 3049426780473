import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomModal from "../../components/high-level/modal/custom-modal";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import general from "../../utils/general";
import { Spin } from "antd";
import useUserType from "../../infrastructure/event-bus/hooks/use-user-type";

const WorkplaceDetailModal = ({ id, nodeKey, handleOk }) => {
  const dispatch = useDispatch();
  const { isSubcontractor } = useUserType();
  const [isOpen, setIsOpen] = useState(true);
  const { isCustomer } = useUserType();
  const [formProps, setFormProps] = useState({});
  const [loading, setLoading] = useState(false);

  const Styles = {
    body: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    container: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      marginBottom: "20px",
      paddingLeft: 0,
      paddingRight: 0,
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "rgb(255 255 255)",
      textAlign: "center",
      height: "50px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#E30613",
      borderTopLeftRadius: 9,
      borderTopRightRadius: 9,
      letterSpacing: "0.4px",
    },
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "workplace",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            setLoading(false);
            setFormProps(data);
          },
        })
      );
    }
  }, []);
  return (
    <CustomModal nodeKey={nodeKey} isOpen={isOpen} footer={null} width={800}>
      <>
        <div style={{ overflowX: "auto", overflowY: "hidden" }}>
          <div className="col-lg-12" style={Styles.body}>
            <div className="col-lg-11" style={Styles.container}>
              <div style={Styles.title}>{i18next.t("workplace_detail.title")}</div>
              {loading ? (
                <div style={{ display: "flex", alignSelf: "center", paddingLeft: "48%", paddingBlock: 50 }}>
                  <Spin />
                </div>
              ) : (
                <div style={{ paddingLeft: 20 }}>
                  <div
                    className="col-lg-12"
                    style={{
                      alignItems: "center",
                      minHeight: 40,
                      display: "flex",
                    }}
                  >
                    <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("workplace.form.name")}:</div>
                    <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.name}</div>
                  </div>

                  {formProps?.address && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("workplace.form.address")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.address}</div>
                    </div>
                  )}
                  {formProps?.workPlaceSubContractors?.length > 0 && !isCustomer && !isSubcontractor && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.subcontractor")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.workPlaceSubContractors?.map((subcontractor) => subcontractor?.subContractor?.name).join(", ")}</div>
                    </div>
                  )}

                  {formProps?.workPlaceUsers?.filter((item) => item?.user)?.length > 0 && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.site_managers")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>
                        {formProps?.workPlaceUsers
                          ?.filter((item) => item?.user)
                          ?.map((user) => user?.user?.name)
                          .join(", ")}
                      </div>
                    </div>
                  )}
                  {formProps?.customer && !isCustomer && (
                    <div
                      className="col-lg-12"
                      style={{
                        alignItems: "center",
                        minHeight: 40,
                        display: "flex",
                      }}
                    >
                      <div style={{ fontWeight: 600, fontSize: 15 }}>{i18next.t("user.form.customer")}:</div>
                      <div style={{ fontSize: 15, marginLeft: 4 }}>{formProps?.customer?.name}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    </CustomModal>
  );
};

export default WorkplaceDetailModal;
