import store from 'store'
import { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { Actions as ApiCallActions } from '../redux/apiCall/reducers'
import { Actions as RootActions } from '../redux/root/reducers'
import { Actions as AuthActions } from '../redux/auth/reducers'
import Axios from 'axios'

import i18next from 'i18next'
import AppConfig from '../utils/config/app-config'
import general from '../utils/general'

const loadi18Next = ({ onSuccess }) => {

  Axios.get(`${AppConfig.API_URL}/translate/json`)
    .then(result => {
      i18next.init({
        lng: "en",
        debug: false,
        resources: {
          en: {
            translation: result?.data?.data
          }
        },
        keySeparator: true,
      })
      onSuccess()
    })
    .catch(err => {
      setTimeout(() => {
        loadi18Next({ onSuccess })
      }, 2000)
    })
}

const loadUserInfo = ({ dispatch, onSuccess }) => {
  const token = store.get('token') || ''
  if (!general.isNullOrEmpty(token)) {
    //user not logged in
    dispatch(
      AuthActions.setAuthState({
        // set token
        token: token,
        isUserAuthorized: true,
      }),
    )
    global.token = token
    var countError = 0
    dispatch(
      ApiCallActions.NativeGet({
        controller: 'auth',
        action: 'UserInfo',
        showAlertOnError: true,
        onSuccess: ({ data }) => {
          if (data == null) {
            localStorage.clear()
          }
          dispatch(
            AuthActions.setAuthState({
              userInfo: data,
            }),
          )
          global.userInfo = data
          global.token = token
          global.roles = new Set(data?.role?.actions)
          onSuccess()
        },
        onError: () => { },
      }),
    )
  } else {
    onSuccess()
  }
}

const LoadApp = ({ }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    loadUserInfo({
      dispatch,
      onSuccess: () => {
        dispatch(
          RootActions.setRootState({
            appIsLoading: false,
          }),
        )
        loadi18Next({
          onSuccess: () => {
            dispatch(
              RootActions.setRootState({
                i18nextLoading: false,
              }),
            )
          },
        })
      },
    })
  }, [])
  return null
}

export default LoadApp
