import { CloseOutlined, EnvironmentOutlined, MailOutlined, SaveOutlined, UserOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormAction, FormContainer, FormInput, FormMultiFileUpload, FormSelect } from "../../components/high-level/form";
import FormDatePicker from "../../components/high-level/form/form-date-picker.js";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container";
import Validator from "../../components/high-level/validator";
import EventBus from "../../infrastructure/event-bus/event-bus";
import useUserType from "../../infrastructure/event-bus/hooks/use-user-type";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import { BucketEnum } from "../../utils/enums/bucket-enum.ts";
import EntityTypeEnum from "../../utils/enums/entity-type-enum.ts";
import FileTypeEnum from "../../utils/enums/file-type-enum.ts";
import StatusEnum from "../../utils/enums/status-enum.ts";
import UserTypeEnum from "../../utils/enums/user-type-enum.ts";
import general from "../../utils/general";

const UserFormModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();
  const { isSubcontractor } = useUserType();
  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, setFormProps] = useState({});
  const userInfo = useSelector((state) => state.auth.userInfo);
  const updateFormProps = (values) => {
    setFormProps((curr) => ({ ...curr, ...values }));
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "user",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            var newData = {
              ...data,
              userType: data?.userType,
              userTypeName: i18next.t(`UserTypeEnum.${data?.userType}`),
              profilePhoto: data?.profilePhoto ? JSON.stringify([{ fileUrl: data?.profilePhoto }]) : null,
              workPlaceIds:
                data?.userType === UserTypeEnum.SiteManager || data?.userType === UserTypeEnum.Employee
                  ? data?.workPlaceUsers?.map((item) => ({
                      id: item?.workPlace?.id,
                      name: item?.workPlace?.name,
                      value: item?.workPlace?.id,
                    }))
                  : null,
              subContractor: data?.subContractorId ? { id: data?.subContractorId, name: data?.subContractor?.name, value: data?.subContractorId } : null,
            };
            setLoading(false);
            setFormProps(newData);
          },
        })
      );
    }
  }, []);
  const save = useCallback(() => {
    const isValid = validator.allValid();
    reRender(1);
    if (isValid) {
      setLoading(true);

      let profilePhotoUrl = null;
      if (formProps?.profilePhoto) {
        const parsedProfilePhoto = JSON.parse(formProps?.profilePhoto);
        profilePhotoUrl = parsedProfilePhoto[0]?.fileUrl;
      }
      let formData = { ...formProps, profilePhoto: profilePhotoUrl };
      if (formProps?.userType === UserTypeEnum.SiteManager || formProps?.userType === UserTypeEnum.Employee) {
        formData = { ...formProps, profilePhoto: profilePhotoUrl, workPlaceIds: formProps?.workPlaceIds.map((x) => x.id) };
        delete formData?.workPlaceId;
      } else {
        formData = { ...formProps, profilePhoto: profilePhotoUrl };
      }
      delete formData?.workPlaceManagers;
      if (formProps?.userType === UserTypeEnum.Administrator) {
        delete formProps?.address;
        delete formProps?.birthDay;
        delete formProps?.workPlaceIds;
        formData = { ...formData, customerId: formProps?.customer?.id ?? null };
      }
      if (formProps?.subContractor) {
        formData = { ...formData, subContractorId: formProps?.subContractor?.id };
      } else {
        formData = { ...formData, subContractorId: null };
      }
      if(formProps?.birthDay) {
        formData = { ...formData, birthDay: dayjs(formProps?.birthDay).format("MM.DD.YYYY") };
      }
      if (general.isNullOrEmpty(id)) {
        // create

        dispatch(
          ApiCallActions.Save({
            controller: "user",
            data: formData,
            showLoading: true,
            onSuccess: async ({ data }) => {
              EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.User, entityId: data });
              closeModal?.();
              general.messageSuccess("general.save_success");
            },
            onError: (message) => general.notificationError(message.errorMessageTechnical),
            callback: () => setLoading(false),
          })
        );
      } else {
        // update

        dispatch(
          ApiCallActions.Update({
            controller: "user",
            // itemId: id,
            data: formData,
            onSuccess: () => {
              EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.User, entityId: id });
              closeModal?.();
              general.messageSuccess("general.update_success");
            },
            onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
            callback: () => setLoading(false),
          })
        );
      }
    }
  }, [formProps]);

  const onChangeBirthdate = useCallback((date) => {
    updateFormProps({ birthDay: date });
  }, []);
  const onChangeWorkplace = useCallback((val) => {
    updateFormProps({ workPlaceIds: Array.isArray(val) ? val : [val] });
  }, []);
  const onChangeSubcontractor = useCallback((val) => {
    updateFormProps({ subContractor: val, workPlaceIds: null, customer: null });
  }, []);
  const onChangeAddress = useCallback((val) => updateFormProps({ address: val }), []);
  const onChangeName = useCallback((val) => updateFormProps({ name: val }), []);
  const onChangeEmail = useCallback((val) => updateFormProps({ email: val?.replaceAll(" ", "")?.trim() }), []);
  const onChangeCustomer = useCallback((val) => {
    updateFormProps({ customer: val, subContractor: null });
  }, []);

  const onChangeUserType = useCallback((userType) => {
    updateFormProps({
      userType: userType?.value,
      userTypeName: userType?.name,
    });
  }, []);

  const onChangeProfilePhoto = useCallback((val) => {
    updateFormProps({ profilePhoto: val });
  }, []);
  const [fileLoading, setFileLoading] = useState(false);
  const onChangeFileLoading = useCallback((val) => setFileLoading(val), []);
  return (
    <ModalFullWidthContainer
      customStyles={{
        marginTop: "100px",
        borderRadius: "15px",
        background: "#f5f5f5",
      }}
      nodeKey={nodeKey}
    >
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        footerActions={[
          {
            show: true,
            node: <FormAction customClassName={"save"} enableFunctionEqualityCheckForMemo loading={loading} text={i18next.t("form.button.save")} iconNode={<SaveOutlined />} buttonType="success" onClick={save} />,
          },
        ]}
        headerTitle={general.isNullOrEmpty(id) ? i18next.t("user.form_title.add") : i18next.t("user.form_title.edit")}
      >
        <div
          style={{
            marginTop: "25px",
          }}
          className="col-12"
        >
          <FormMultiFileUpload
            accept={".png,.jpg,.jpeg"}
            label={i18next.t("user.form.profile_photo")}
            maxFileCount={1}
            cropFile
            bucket={BucketEnum.ProfilePhoto}
            fileType={FileTypeEnum.ProfilePhoto}
            value={formProps?.profilePhoto ? formProps?.profilePhoto : []}
            onChangeLoading={onChangeFileLoading}
            onChange={onChangeProfilePhoto}
            style={{ width: "100%" }}
            imageDirection="column"
          />
        </div>
        <div style={{ backgroundColor: "white", borderRadius: "15px", padding: "25px 20px" }} className="col-12">
          <div className="row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormSelect
                addRequiredSign
                className="userType"
                controller="common"
                actionType="NativeGet"
                isUiFilter={true}
                disabled={id ? true : false}
                action={"userType"}
                label={i18next.t("user.form.user_type")}
                selectedId={formProps?.userType}
                selectedName={i18next.t(formProps?.userTypeName)}
                onChange={onChangeUserType}
                filter={{ userType: formProps?.userType }}
                errorMessage={validator.register("userType", formProps?.userType, [{ rule: "required" }], validatorScopeKey)}
              />
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormInput
                addRequiredSign
                className="name"
                label={i18next.t("user.form.name")}
                placeholder={i18next.t("user.form.name")}
                onChange={onChangeName}
                value={formProps?.name}
                prefix={<UserOutlined />}
                errorMessage={validator.register("name", formProps?.name, [{ rule: "required" }, { rule: "maxStringLength", value: 50 }], validatorScopeKey)}
              />
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
              <FormInput
                addRequiredSign
                className="email"
                label={i18next.t("user.form.email")}
                placeholder={i18next.t("user.form.email")}
                onChange={onChangeEmail}
                value={formProps?.email}
                prefix={<MailOutlined />}
                forceLowerCase={true}
                errorMessage={validator.register("email", formProps?.email, [{ rule: "isEmail" }, { rule: "maxStringLength", value: 50 }, { rule: "required" }], validatorScopeKey)}
              />
            </div>

            {(formProps?.userType === UserTypeEnum.Employee || formProps?.userType === UserTypeEnum.SiteManager) && (
              <>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                  <FormDatePicker
                    addRequiredSign
                    label={i18next.t("user.form.birth_date")}
                    value={formProps?.birthDay ? formProps?.birthDay : null}
                    onChange={onChangeBirthdate}
                    maxDate={dayjs()}
                    errorMessage={validator.register("birthDay", formProps?.birthDay, [{ rule: "required" }], validatorScopeKey)}
                  />
                </div>

                <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                  <FormInput
                    addRequiredSign
                    className="address"
                    label={i18next.t("user.form.address")}
                    placeholder={i18next.t("user.form.address")}
                    onChange={onChangeAddress}
                    value={formProps?.address}
                    prefix={<EnvironmentOutlined />}
                    errorMessage={validator.register("address", formProps?.address, [{ rule: "required" }, { rule: "maxStringLength", value: 150 }], validatorScopeKey)}
                  />
                </div>
              </>
            )}

            {!userInfo?.subContractorId && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                <FormSelect
                  className="subcontractor"
                  controller="subContractor"
                  isUiFilter={true}
                  action={"dropdown"}
                  label={i18next.t("user.form.subcontractor")}
                  selectedId={formProps?.subContractor?.id}
                  selectedName={formProps?.subContractor?.name}
                  value={formProps?.subContractor}
                  onChange={onChangeSubcontractor}
                  filter={{ status: StatusEnum.Active }}
                />
              </div>
            )}

            {(formProps?.userType === UserTypeEnum.Employee || formProps?.userType === UserTypeEnum.SiteManager) && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                <FormSelect
                  addRequiredSign
                  className="workplace"
                  controller="workplace"
                  isUiFilter={true}
                  action={"dropdown"}
                  isMulti={formProps?.userType === UserTypeEnum.SiteManager}
                  label={i18next.t("user.form.workplace")}
                  selectedId={formProps?.workPlaceIds}
                  selectedName={formProps?.workPlaceIds}
                  value={formProps?.workPlaceIds}
                  onChange={onChangeWorkplace}
                  filter={{ status: StatusEnum.Active, subContractorId: formProps?.subContractor?.id }}
                  errorMessage={validator.register("workplace", formProps?.workPlaceIds, [{ rule: "required" }], validatorScopeKey)}
                />
              </div>
            )}

            {formProps?.userType === UserTypeEnum.Administrator && !isSubcontractor && (
              <div className="col-12 col-sm-6 col-md-6 col-lg-6 mt-3">
                <FormSelect className="customer" controller="customer" isUiFilter={true} action={"dropdown"} label={i18next.t("user.form.customer")} selectedId={formProps?.customer?.id} selectedName={formProps?.customer?.name} value={formProps?.customer} onChange={onChangeCustomer} />
              </div>
            )}
          </div>
        </div>
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default UserFormModal;
