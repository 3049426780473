const GridIcon = ({ backgroundColor, icon }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 100,
        backgroundColor: backgroundColor || "transparent",
        height: 29,
        width: 29,
      }}
    >
      {icon}
    </div>
  );
};

export default GridIcon;
