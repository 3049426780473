import React from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { withRouter } from "react-router-dom";
import ModalRenderContainer from "../../components/high-level/modal/modal-render-container";
import classNames from "classnames";
import style from "./style.module.scss";
const mapStateToProps = ({ settings }) => ({
  logo: settings.logo,
  isGrayTopbar: settings.isGrayTopbar,
  isCardShadow: settings.isCardShadow,
  isSquaredBorders: settings.isSquaredBorders,
  isBorderless: settings.isBorderless,
  authPagesColor: settings.authPagesColor,
});

const AuthLayout = ({ children, isCardShadow, isSquaredBorders, isBorderless, authPagesColor }) => {
  return (
    <Layout>
      <Layout.Content>
        <div
          className={classNames({
            cui__layout__squaredBorders: isSquaredBorders,
            cui__layout__cardsShadow: isCardShadow,
            cui__layout__borderless: isBorderless,
            [style.white]: authPagesColor === "white",
            [style.gray]: authPagesColor === "gray",
          })}
        >
          {children}
        </div>

        <ModalRenderContainer />
      </Layout.Content>
    </Layout>
  );
};

export default withRouter(connect(mapStateToProps)(AuthLayout));
