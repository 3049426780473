import { CloseOutlined } from "@ant-design/icons";
import i18next from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsPaperclip, BsSend } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { FormAction, FormMultiFileUpload } from "../../components/high-level/form/index.js";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container.js";
import Validator from "../../components/high-level/validator/index.js";
import EventBus from "../../infrastructure/event-bus/event-bus.js";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers.js";
import general from "../../utils/general.js";

import { Button, Modal } from "antd";
import moment from "moment";
import FormInput from "../../components/high-level/form/form-input.js";
import { BucketEnum } from "../../utils/enums/bucket-enum.ts";
import EntityTypeEnum from "../../utils/enums/entity-type-enum.ts";
import FileTypeEnum from "../../utils/enums/file-type-enum.ts";
import UserTypeEnum from "../../utils/enums/user-type-enum.ts";
import useRefState from "../../utils/use-ref-state.js";
import { TicketStatusEnum } from "../../utils/enums/ticket-status-enum.ts";
import SVG_FILES from "../../utils/svg.js";
import GridPopconfirmAction from "../../components/high-level/virtualized-grid/grid-popconfirm-action.js";
import GridIcon from "../../components/low-level/grid-icon/index.js";

const TicketDetailModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();

  //#region State Operations

  const [, reRender] = useState(null);
  const userInfo = useSelector((state) => state.auth?.userInfo);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [formProps, setFormProps] = useState({});
  const [message, messageRef, setMessage] = useRefState("");
  const [documents, documentsRef, setDocuments] = useRefState([]);
  const [fileUploadVisible, setFileUploadVisible] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const updateFormProps = (values) => setFormProps((curr) => ({ ...curr, ...values }));
  const otherUserInfo = formProps?.messages?.filter((x) => x?.userId !== userInfo?.id)[0]?.user;

  //#endregion

  //#region Api Calls

  useEffect(() => {
    loadData();
    setTimeout(() => {
      if (messageInputRef.current) {
        messageInputRef?.current?.focus();
      }
    }, 100);
  }, []);

  const onChangeFileLoading = useCallback((val) => setFileLoading(val), []);
  const onChangeDocument = useCallback((val) => {
    setDocuments(val);
  }, []);

  const closeTicket = useCallback(() => {
    dispatch(
      ApiCallActions.NativePost({
        controller: "ticket",
        action: "close/" + id,
        onSuccess: () => {
          closeModal();
          EventBus.sc.onEntityDeleted({ entityType: EntityTypeEnum.Ticket, entityId: id });
          general.messageSuccess("general.success");
        },
        onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
        callback: () => setLoading(false),
      })
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000);

    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 2000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [formProps.messages]);

  const loadData = () => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "ticket",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            const sortData = data?.messages?.sort((a, b) => new Date(a?.createdDate) - new Date(b?.createdDate));
            updateFormProps({ ...data, messages: sortData });
            if (messagesEndRef.current) {
              messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
            }
          },
          callback: () => setLoading(false),
        })
      );
    }
  };
  const save = useCallback(() => {
    const isValid = validator.allValid();
    // if (document || (messageRef?.current && messageRef?.current?.length > 0)) {
    let documentUrl = null;
    let documentExtensionType = null;
    if (documentsRef?.current && documentsRef?.current?.length > 0) {
      const parsedDocumentUrl = JSON.parse(documentsRef.current);
      documentUrl = parsedDocumentUrl[0]?.fileUrl;
      documentExtensionType = parsedDocumentUrl[0]?.fileType;
    }
    if (documentUrl === null && messageRef?.current?.length === 0 && fileUploadVisible) {
      general.notificationError(i18next.t("general.please_select_file"));
      return;
    }
    reRender(1);
    if (messageRef.current || documentUrl) {
      setLoading(true);
      dispatch(
        ApiCallActions.NativePost({
          controller: "ticket",
          action: "answer",
          data: {
            ticketId: id,
            message: messageRef.current,
            documentUrl: documentUrl,
            documentExtensionType: documentExtensionType,
          },
          onSuccess: () => {
            setTimeout(() => {
              setMessage("");
              setDocuments([]);
              loadData();
              setFileUploadVisible(false);
            }, 200);
            EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.Ticket, entityId: id });
            general.messageSuccess("general.message_send_success");
          },
          onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
          callback: () => setLoading(false),
        })
      );
    }
    // }
  }, [formProps, messageRef.current, message, formProps?.document, fileUploadVisible, setFileUploadVisible]);
  //#endregion

  return (
    <ModalFullWidthContainer
      customStyles={{
        marginTop: "100px",
        borderRadius: "15px",
        background: "#f5f5f5",
      }}
      nodeKey={nodeKey}
    >
      <Modal
        visible={fileUploadVisible}
        style={{ paddingTop: 20, justifyContent: "center", alignItems: "center" }}
        footer={null}
        width={500}
        title={i18next.t("general.file_upload")}
        onCancel={() => {
          setDocuments([]);
          setFileUploadVisible(false);
        }}
      >
        <div className="mb-3 mt-3">
          <FormMultiFileUpload accept={".png,.jpg,.jpeg,.pdf,.docx,.doc,.xlsx,.xls,.csv"} bucket={BucketEnum.TicketDocument} maxFileCount={1} fileType={FileTypeEnum.GeneralDocument} value={documents ? documents : []} onChangeLoading={onChangeFileLoading} onChange={onChangeDocument} />
        </div>
        <div>
          <Button onClick={save} style={{ marginLeft: 3, color: "white", borderWidth: 1, backgroundColor: "blue" }}>
            {i18next.t("general.send")}
          </Button>
        </div>
      </Modal>
      <div className="row ">
        <div className="col-12 col-md-12">
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "15px",
              padding: "10px 20px",
            }}
            className="card-header card-header-flex align-items-center"
          >
            <div style={{ justifyContent: "space-between" }} className="d-flex flex-row align-items-center mr-auto">
              <div className="d-flex flex-row align-items-center">
                <img src={otherUserInfo?.profilePhoto ? otherUserInfo?.profilePhoto : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"} alt="profilePhoto" style={{ width: 50, height: 50, borderRadius: 12, marginRight: 10 }} />
                <div>
                  <h5 className="mb-0 mr-3 " style={{ marginRight: "20px", fontSize: 16, fontWeight: 700 }}>
                    <strong>{otherUserInfo?.name}</strong>
                  </h5>
                  <h3 className="mb-0 mr-3" style={{ marginRight: "20px", fontSize: 14, fontWeight: 500, color: "rgba(164, 164, 164, 1)", marginTop: 2 }}>
                    {formProps?.title}
                  </h3>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {formProps?.ticketStatus !== TicketStatusEnum.Closed && (
                <GridPopconfirmAction
                  actionProps={{
                    buttonType: "danger",
                    iconNode: <GridIcon icon={SVG_FILES.Exit} />,
                    tooltip: i18next.t("ticket.button.close"),
                  }}
                  confirmationText={i18next.t("ticket_detail.close_ticket.desc")}
                  onClickYes={closeTicket}
                />
              )}
              <FormAction iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />
            </div>
          </div>
          <div
            className="card"
            style={{
              height: "calc(100vh - 320px)",
              marginTop: 10,
              borderRadius: "15px",
              overflow: "hidden",
              border: "none",
            }}
          >
            <div style={{ overflowY: "auto", padding: 15 }}>
              {formProps?.messages?.map((message, index) => {
                const isMine = userInfo?.id === message?.userId || message?.user?.userType === UserTypeEnum.Administrator;
                const messageContainerStyle = {
                  
                  display: "flex",
                  justifyContent: isMine ? "flex-end" : "flex-start",
                  marginBottom: 10,
                };
                const messageStyle = {
                  backgroundColor: isMine ? "#FFCDCD" : "#F6F6F6",
                  padding: 10,
                  borderRadius: 10,
                  borderStartStartRadius: 10,
                  borderStartEndRadius: 10,
                  borderEndStartRadius: isMine ? 10 : 0,
                  borderEndEndRadius: isMine ? 0 : 10,
                  maxWidth: 400,
                  textAlign:  "left",
                  minWidth: 40,
                };
                return (
                  <>
                    <div key={index} style={messageContainerStyle}>
                      <div style={messageStyle}>
                        {isMine && <div style={{ fontSize: 12, color: "#D63939", fontWeight: 700 }}>{message?.user?.name}</div>}
                        {message?.message ? (
                          message?.message.split("\n").map((line, index) => (
                            <div key={index}>
                              {line}
                              <br />
                            </div>
                          ))
                        ) : message?.documentUrl && general.isImageExtension(message?.documentExtensionType) ? (
                          <div style={{ marginTop: 5, maxWidth: 200, maxHeight: 300 }}>
                            <img src={message?.documentUrl} style={{ maxWidth: 200, maxHeight: 300, borderRadius: 10 }} alt="document" onClick={() => window.open(message?.documentUrl, "_blank")} />
                          </div>
                        ) : ["docx", "doc"].includes(message?.documentExtensionType?.toLowerCase()) ? (
                          <img src={require("../../assets/images/word-image.png")} style={{ width: 50, height: 50, borderRadius: 10 }} alt="document" onClick={() => window.open(message?.documentUrl, "_blank")} />
                        ) : ["xls", "xlsx", "csv"].includes(message?.documentExtensionType?.toLowerCase()) ? (
                          <img src={require("../../assets/images/excel-image.png")} style={{ width: 50, height: 50, borderRadius: 10 }} alt="document" onClick={() => window.open(message?.documentUrl, "_blank")} />
                        ) : (
                          message?.documentUrl && <img src={require("../../assets/images/pdf-image.png")} style={{ width: 50, height: 50, borderRadius: 10 }} alt="document" onClick={() => window.open(message?.documentUrl, "_blank")} />
                        )}
                        {/* {message?.message} */}
                      </div>
                    </div>
                    <div style={{ textAlign: isMine ? "right" : "left", fontSize: 14, color: "black", fontWeight: 400, marginTop: -5, marginBottom: 10 }}>
                      {moment(message?.createdDate)?.isBefore(moment(), "day") ? moment(message?.createdDate).format("DD.MM.YYYY HH:mm") : moment(message?.createdDate).fromNow()}
                    </div>
                  </>
                );
              })}
              <div ref={messagesEndRef} />
            </div>
          </div>
          {formProps?.ticketStatus !== TicketStatusEnum.Closed && (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <div style={{ flex: 1 }}>
                <FormInput
                  style={{
                    backgroundColor: "rgba(237, 237, 237, 1)",
                    borderRadius: 40,
                    height: 55,
                    paddingLeft: 20,
                  }}
                  prefix={
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={save}
                    >
                      <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_22_132091)">
                          <path
                            d="M19.3387 18.4035L14.2231 24.6687C14.1643 24.7407 14.1255 24.827 14.1106 24.9188C14.0957 25.0106 14.1053 25.1047 14.1383 25.1916L16.9371 32.5439C17.1873 33.2265 18.1461 33.2441 18.4193 32.5697L26.4486 12.8225C26.504 12.6859 26.5203 12.5366 26.4957 12.3912C26.4711 12.2459 26.4065 12.1103 26.3092 11.9996C26.2119 11.8888 26.0857 11.8074 25.9447 11.7643C25.8037 11.7212 25.6536 11.7181 25.511 11.7555L4.89561 17.1803C4.19239 17.3654 4.08661 18.3185 4.73074 18.6536L11.6631 22.3734C11.7451 22.4173 11.8372 22.4389 11.9301 22.4359C12.0231 22.4329 12.1136 22.4056 12.1926 22.3565L19.0633 18.0885C19.1059 18.0617 19.1567 18.0508 19.2066 18.0581C19.2565 18.0653 19.3021 18.09 19.3354 18.1279C19.3687 18.1657 19.3874 18.2142 19.3881 18.2646C19.3888 18.315 19.3716 18.364 19.3394 18.4028L19.3387 18.4035Z"
                            fill="#E30613"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_22_132091">
                            <rect width="25.4222" height="25.4222" fill="white" transform="translate(0.613525 17.6592) rotate(-41.308)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  }
                  addRequiredSign={false}
                  inputRef={messageInputRef}
                  placeholderValue={i18next.t("ticket_detail.type_message")}
                  label={""}
                  multiLine={true}
                  onChange={setMessage}
                  value={message}
                  autoSize={true}
                  allowClear={false}
                />
              </div>
              <div>
                <FormAction
                  text=""
                  onClick={() => setFileUploadVisible(true)}
                  iconNode={
                    <BsPaperclip
                      style={{
                        width: "20px !important",
                        height: "20px !important",
                      }}
                      fill="rgba(255, 100, 100, 1)"
                      size={20}
                    />
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalFullWidthContainer>
  );
};

export default TicketDetailModal;
