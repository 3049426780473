import { DatePicker, Form, Radio } from "antd";
import i18next from "i18next";
import React from "react";
import general from "../../../utils/general";
import dayjs from "dayjs";
import FloatLabel from "../../low-level/float-label";

const { RangePicker } = DatePicker;
const FormRangePicker = ({ label, value, minDate, maxDate, placeholders = [i18next.t("form.select.placeholder"), i18next.t("form.select.placeholder")], errorMessage, disabled, rightAction, dateFormat = "DD.MM.YYYY", onChange, addRequiredSign }) => {
  // const onCahngeEvent = (e) => {
  //     if (onChange instanceof Function)
  //         onChange(e);
  // }

  // const minDateHook = minDate?.setHours(0,0,0,0);
  // const maxDateHook = maxDate?.setHours(0,0,0,0);

  // const disabledDate = (current) => {
  //     if (minDateHook && current && minDateHook > current)
  //         return true;
  //     if (maxDateHook && current && maxDateHook < current)
  //         return true;
  //     return false;
  // }

  const onCahngeEvent = (e) => {
    if (e?.length > 1) {
      e = [dayjs(e[0]).startOf("day"), dayjs(e[1]).startOf("day").add(1, "days").add(-1, "seconds")];
    }
    if (onChange instanceof Function) onChange(e);
  };

  const minDateHook = general.isNullOrEmpty(minDate) ? null : dayjs(minDate).startOf("day");
  const maxDateHook = general.isNullOrEmpty(maxDate) ? null : dayjs(maxDate).startOf("day");

  const disabledDate = (current) => {
    if (minDateHook && current && minDateHook > dayjs(current).startOf("day")) return true;
    if (maxDateHook && current && maxDateHook < dayjs(current).startOf("day")) return true;
    return false;
  };

  return (
    <Form.Item
      /*  label={label} */
      validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
      help={errorMessage ?? ""}
    >
      <FloatLabel addRequiredSign label={label} name={label} value={value} disabled={disabled}>
        <RangePicker
          style={{ display: "flex", width: "100%" }}
          disabledDate={disabledDate}
          disabled={disabled}
          value={value}
          size="large"
          className="range-picker-placeholder"
          allowClear
          onChange={onCahngeEvent}
          placeholder={[]}
          /*  placeholder={placeholders} */
          // placeholder={placeholder}
          // defaultValue={defaultValue}
          format={dateFormat}
        />
        {rightAction}
      </FloatLabel>
    </Form.Item>
  );
};

const areEqual = (prev, next) => {
  return (
    prev.label === next.label &&
    JSON.stringify(prev.value) === JSON.stringify(next.value) &&
    JSON.stringify(prev.minDate) === JSON.stringify(next.minDate) &&
    JSON.stringify(prev.maxDate) === JSON.stringify(next.maxDate) &&
    JSON.stringify(prev.placeholders) === JSON.stringify(next.placeholders) &&
    prev.dateFormat === next.dateFormat &&
    prev.errorMessage === next.errorMessage &&
    prev.disabled === next.disabled
  );
};
export default React.memo(FormRangePicker, areEqual);
