export default function getMenuData() {
  const isSubcontractor = global.userInfo?.subContractorId;
  const isCustomer = global.userInfo?.customerId;

  var menu = [];

  menu.push({
    title: "left_menu.home",
    key: "home",
    icon: "fe fe-cpu",
    url: "/dashboard/alpha",
  });

  menu.push({
    title: "left_menu.user_list",
    key: "users",
    url: "/user",
    icon: "fe fe-users"
  })

  menu.push({
    title: "left_menu.user_document_list",
    key: "user-documents",
    url: "/user-documents",
    icon: "fe fe-file-text"
  });

  if (!isSubcontractor && !isCustomer)
    menu.push({
      title: "left_menu.general_document_list",
      key: "general-documents",
      url: "/general-documents",
      icon: "fe fe-download-cloud"
    });

  if (!isCustomer)
    menu.push({
      title: "left_menu.ticket_list",
      key: "tickets",
      url: "/tickets",
      icon: "fe fe-message-circle"
    });

  menu.push({
    title: "left_menu.working_time_calendar",
    key: "working-time-calendar",
    url: "/working-time-calendar",
    icon: "fe fe-clock"
  });

  menu.push({
    title: "left_menu.report",
    key: "report",
    url: "/report",
    icon: "fe fe-bar-chart-2"
  });

  menu.push({
    title: "left_menu.workplace_list",
    key: "workplaces",
    url: "/workplace",
    icon: "fe fe-home"
  });

  if (!isSubcontractor && !isCustomer)
    menu.push({
      title: "left_menu.subcontractor_list",
      key: "subcontractors",
      url: "/subcontractors",
      icon: "fe fe-share-2"
    });

  if (!isSubcontractor && !isCustomer)
    menu.push({
      title: "left_menu.customer_list",
      key: "customer",
      url: "/customer",
      icon: "fe fe-pocket"
    });

    if (!isSubcontractor && !isCustomer)
  menu.push({
    title: "left_menu.document_type_list",
    key: "documenttypes",
    url: "/documenttype",
    icon: "fe fe-more-vertical"
  });

  if (!isSubcontractor && !isCustomer)
  menu.push({
    title: "left_menu.translate_list",
    key: "translate",
    url: "/translate",
    icon: "fe fe-globe"
  });

  if (!isSubcontractor && !isCustomer)
  menu.push({
    title: "left_menu.screen_record_log_list",
    key: "screen-record-logs",
    url: "/screen-record-logs",
    icon: "fe fe-list"
  });

  return menu;
}
