import React, { useState } from "react";
import i18next from "i18next";
import general from "../../../utils/general";


const SubHeader = ({ header, children }) => {

    return (
        <div className="col-12 hr-text">
            <hr />
            {(!general.isNullOrEmpty(header) || children) &&
                <>
                    <span className="text-center">
                        {i18next.t(header)}
                        {children}
                    </span>
                    <hr />
                </>
            }
        </div>
    );
};

export default SubHeader;
