import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import i18next from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormAction, FormContainer, FormMultiFileUpload } from "../../components/high-level/form";
import ModalFullWidthContainer from "../../components/high-level/modal/modal-full-width-container";
import Validator from "../../components/high-level/validator";
import EventBus from "../../infrastructure/event-bus/event-bus";
import { Actions as ApiCallActions } from "../../redux/apiCall/reducers";
import { BucketEnum } from "../../utils/enums/bucket-enum.ts";
import EntityTypeEnum from "../../utils/enums/entity-type-enum.ts";
import FileTypeEnum from "../../utils/enums/file-type-enum.ts";
import general from "../../utils/general";
import FormInput from "../../components/high-level/form/form-input.js";

const GeneralDocumentModal = ({ nodeKey, id, closeModal }) => {
  const dispatch = useDispatch();

  const [, reRender] = useState(null);
  const [validator] = useState(new Validator());
  const validatorScopeKey = validator.generateNewScopeKey();

  const [loading, setLoading] = useState(false);
  const [formProps, setFormProps] = useState({});
  const updateFormProps = (values) => {
    setFormProps((curr) => ({ ...curr, ...values }));
  };

  useEffect(() => {
    if (!general.isNullOrEmpty(id)) {
      setLoading(true);
      dispatch(
        ApiCallActions.Detail({
          controller: "document",
          itemId: id,
          showLoading: true,
          showAlertOnError: true,
          onSuccess: async ({ data }) => {
            var newData = {
              ...data,
              document: JSON.stringify([{ fileUrl: data.documentUrl, fileType: data.documentExtensionType }]),
            };
            setLoading(false);
            setFormProps(newData);
          },
        })
      );
    }
  }, []);

  const save = useCallback(() => {
    const isValid = validator.allValid();
    reRender(1);
    if (isValid) {
      setLoading(true);
      if (general.isNullOrEmpty(id)) {
        // create
        let documentUrl = null;
        let documentExtensionType = null;
        if (formProps?.document) {
          const parsedDocumentUrl = JSON.parse(formProps?.document);
          documentUrl = parsedDocumentUrl[0]?.fileUrl;
          documentExtensionType = parsedDocumentUrl[0]?.fileType;
        }
        dispatch(
          ApiCallActions.Save({
            controller: "document",
            data: { documentList: [{ name: formProps?.name, documentUrl: documentUrl, documentExtensionType: documentExtensionType }] },
            showLoading: true,
            onSuccess: async ({ data }) => {
              await data.forEach((id) => {
                EventBus.sc.onEntityCreated({ entityType: EntityTypeEnum.GeneralDocument, entityId: id });
              });
              closeModal?.();
              general.messageSuccess("general.save_success");
            },
            onError: (message) => general.notificationError(message.errorMessageTechnical),
            callback: () => setLoading(false),
          })
        );
      } else {
        // update
        let documentUrl = null;
        let documentExtensionType = null;
        if (formProps?.document) {
          const parsedDocumentUrl = JSON.parse(formProps?.document);
          documentUrl = parsedDocumentUrl[0]?.fileUrl;
          documentExtensionType = parsedDocumentUrl[0]?.fileType;
        }

        dispatch(
          ApiCallActions.Update({
            controller: "document",
            action: "editForGeneral",
            data: { id: formProps?.id, name: formProps?.name, documentUrl: documentUrl, documentExtensionType: documentExtensionType },
            onSuccess: () => {
              EventBus.sc.onEntityUpdated({ entityType: EntityTypeEnum.GeneralDocument, entityId: id });
              closeModal?.();
              general.messageSuccess("general.update_success");
            },
            onError: (message) => general.notificationError(i18next.t(message.errorMessageTechnical)),
            callback: () => setLoading(false),
          })
        );
      }
    }
  }, [formProps]);

  const onChangeName = useCallback((val) => updateFormProps({ name: val }), []);

  const onChangeDocument = useCallback((val) => {
    updateFormProps({ document: val });
  }, []);
  const [fileLoading, setFileLoading] = useState(false);
  const onChangeFileLoading = useCallback((val) => setFileLoading(val), []);
  return (
    <ModalFullWidthContainer
      customStyles={{
        marginTop: "100px",
        borderRadius: "15px",
        background: "#f5f5f5",
      }}
      nodeKey={nodeKey}
    >
      <FormContainer
        headerActions={[
          {
            show: true,
            node: <FormAction iconNode={<CloseOutlined />} buttonType="light" onClick={closeModal} />,
          },
        ]}
        footerActions={[
          {
            show: true,
            node: <FormAction customClassName={"save"} enableFunctionEqualityCheckForMemo loading={loading} text={i18next.t("form.button.save")} iconNode={<SaveOutlined />} buttonType="success" onClick={save} />,
          },
        ]}
        headerTitle={general.isNullOrEmpty(id) ? i18next.t("general_document.form_title.add") : i18next.t("general_document.form_title.edit")}
      >
        <div style={{ backgroundColor: "white", borderRadius: "15px", padding: "25px 20px", marginTop: 10 }} className="col-12">
          <div className="row">
            <div className="col-12">
              <fieldset>
                <div className="row">
                  <div className="col-md-3">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        minHeight: "200px",
                        border: "1px solid #d9d9d9",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                    >
                      <FormMultiFileUpload
                        accept={".png,.jpg,.jpeg,.pdf,.docx,.doc,.xlsx,.xls,.csv"}
                        bucket={BucketEnum.GeneralDocument}
                        maxFileCount={1}
                        fileType={FileTypeEnum.GeneralDocument}
                        value={formProps?.document ? formProps?.document : []}
                        onChangeLoading={onChangeFileLoading}
                        onChange={onChangeDocument}
                        errorMessage={validator.register("document", formProps?.document, [{ rule: "required" }], validatorScopeKey)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mt-5">
                    <FormInput
                      addRequiredSign
                      className="name"
                      label={i18next.t("general_documents.form.name")}
                      placeholder={i18next.t("general_documents.form.name")}
                      maxLength={50}
                      value={formProps?.name}
                      onChange={(val) => onChangeName(val)}
                      errorMessage={validator.register("name", formProps?.name, [{ rule: "required" }], validatorScopeKey)}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </FormContainer>
    </ModalFullWidthContainer>
  );
};

export default GeneralDocumentModal;
