import { CloseOutlined } from "@ant-design/icons";
import { Form } from "antd";
import i18next from "i18next";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AsyncPaginate } from "react-select-async-paginate";
import { Actions as ApiCallActions } from "../../../redux/apiCall/reducers";
import general from "../../../utils/general";
import FloatLabel from "../../low-level/float-label";

const FormSelect = ({
  label,
  placeholder = i18next.t("form.select.placeholder"),
  addRequiredSign = false,
  selectedId,
  selectedName,
  errorMessage,
  antdFormItemProps = {},
  onChange,
  controller = "user",
  idKey = "id",
  disabled,
  action = "dropdown",
  filter,
  searchTextKey = "searchText",
  isClearable = true,
  rightAction,
  getOptionLabel = (option) => option?.name,
  nameKey = "name",
  valueKey = "value",
  noRecordText = "general.no_records_found",
  show = true,
  isTranslate = true,
  className = "",
  isUiFilter = false,
  customData,
  isMulti = false,
  value,
  isInsertNewValue = false, //Searchte bulunamazsa yeni kayıt eklemek için kullanılır.
  isValid,
  apiService = "AdminService",
  actionType = "List",
  customStyle,
  filterData,
}) => {
  const dispatch = useDispatch();
  const [clearCache, setClearCache] = useState([general.generateRandomString(10)]);

  const onCahngeEvent = (e) => {
    if (onChange instanceof Function) onChange(e);
  };

  const loadOptions = (search, loadedOptions, { page }) => {
    if (!general.isNullOrEmpty(customData)) {
      let list = customData.slice();
      if (isInsertNewValue && !general.isNullOrEmpty(search)) {
        const containsSearch = customData.some((item) => item.name?.toLowerCase() === search.toLowerCase());
        if (!containsSearch) list.push({ name: search + " - Yeni Kayıt", value: search });
      }
      if (isUiFilter) list = list.filter((x) => general.turkishToEnglishChars(x.name).toLowerCase().includes(general.turkishToEnglishChars(search).toLowerCase()));

      return Promise.resolve({
        options: list,
      });
    } else {
      return new Promise((resolve, reject) => {
        var searchTextObj = {};
        searchTextObj[searchTextKey] = search;
        if (isValid) {
          if (!isValid(search)) {
            resolve({
              options: [],
            });
            return;
          }
        }
        if (show) {
          dispatch(
            (actionType === "NativeGet" ? ApiCallActions.NativeGet : ApiCallActions.List)({
              controller: controller,
              action: action,
              apiService: apiService,
              data: {
                pageSize: 100,
                pageNumber: page,
                ...searchTextObj,
                ...filter,
              },
              showAlertOnError: true,
              onSuccess: ({ data, pagination }) => {
                const hasMore = general.isNullOrEmpty(pagination) ? false : pagination?.pageCount > pagination?.pageNumber;
                let translateData = data.map((e) => {
                  e.name = isTranslate ? i18next.t(e.name) : e.name;
                  e.value = e?.value ?? e?.[idKey];
                  return e;
                });

                if (isUiFilter) translateData = translateData.filter((x) => general.turkishToEnglishChars(x.name).toLowerCase().includes(general.turkishToEnglishChars(search).toLowerCase()));
                if (filterData) translateData = filterData(translateData);

                resolve({
                  options: translateData?.length > 0 ? translateData : isInsertNewValue ? [{ name: search, value: search }] : [],
                  hasMore: hasMore,
                  additional: {
                    page: page + 1,
                  },
                });
              },
              onError: ({ errorMessage }) => reject(i18next.t(errorMessage)),
            })
          );
        } else {
          resolve({
            options: [],
          });
        }
      });
    }
  };

  const noOptMsg = () => i18next.t(noRecordText);
  const loadingMsg = () => i18next.t("general.loading");

  const getStyles = {
    control: (baseControl) => ({
      ...baseControl,

      borderColor: general.isNullOrEmpty(errorMessage) ? "transparent" : "#f5222e",
      // height: '40px',
      minHeight: "40px",
      borderRadius: "8px",
    }),
    container: (baseContainer) => ({
      ...baseContainer,
      width: "100%",
      border: "1px solid rgba(233, 238, 243, 1)",
      borderRadius: "8px",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: 150,
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  let val = {};
  val[nameKey] = selectedName;
  val[valueKey] = selectedId;
  return (
    <Form.Item
      style={{
        border: "none",
      }}
      /*  label={label} */
      validateStatus={general.isNullOrEmpty(errorMessage) ? "" : "error"}
      help={errorMessage ?? ""}
      {...antdFormItemProps}
    >
      <FloatLabel style={customStyle} addRequiredSign={addRequiredSign} label={label} name={label} value={value ? value : selectedId ? val : null} disabled={disabled}>
        <AsyncPaginate
          cacheUniqs={clearCache}
          isClearable={isClearable}
          getOptionLabel={getOptionLabel}
          onMenuClose={() => setClearCache([general.generateRandomString(10)])}
          debounceTimeout={100}
          placeholder={""}
          className={"border-style " + className}
          menuPosition="fixed"
          menuPlacement="auto"
          isDisabled={disabled}
          styles={getStyles}
          noOptionsMessage={noOptMsg}
          loadingMessage={loadingMsg}
          loadOptions={loadOptions}
          onChange={onCahngeEvent}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          value={value != undefined ? value : selectedId ? val : null}
          additional={{
            page: 1,
          }}
          components={{
            MultiValue: (props) => {
              return (
                <div
                  style={{
                    backgroundColor: "#E30613 ",
                    color: "white",
                    borderRadius: "5px",
                    padding: "2px 5px",
                    margin: "2px",
                    display: "flex",
                    fontSize: "14px",
                  }}
                >
                  <div>{props.data.name}</div>
                  <div
                    onClick={() => {
                      const newValue = value.filter((item) => item.value !== props.data.value);
                      onCahngeEvent(newValue);
                    }}
                  >
                    <CloseOutlined style={{ marginLeft: 7, cursor: "pointer", width: 10, height: 10 }} />
                  </div>
                </div>
              );
            },
            SingleValue: (props) => {
              return (
                <div
                  style={{
                    backgroundColor: !disabled && "#E30613",
                    color: !disabled && "white",
                    borderRadius: "5px",
                    padding: "2px 5px",
                    margin: "2px",
                    marginBottom: "4px",
                    display: "inline-block",
                    fontSize: "14px",
                    width: "fit-content",
                    marginTop: -24,
                    height: "fit-content",
                  }}
                >
                  {props.data.name}
                </div>
              );
            },
            // ValueContainer: (props) => {
            //   return (
            //     <div
            //       style={{
            //         display: "flex",
            //         flexWrap: "wrap",
            //         alignItems: "center",
            //         overflow: "hidden",
            //         height: "100%",
            //         margin: "0px 6px",
            //         marginTop: 4,
            //         marginBottom: 4,
            //       }}
            //     >
            //       {props.children}
            //     </div>
            //   );
            // },
            DropdownIndicator: () => {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "0px 5px",
                  }}
                >
                  <img src={require("../../../assets/images/dropdown.png")} style={{ width: 20, height: 20 }} />
                </div>
              );
            },
            IndicatorSeparator: () => {
              return null;
            },
            // ClearIndicator: () => {
            //   return (
            //     <div
            //       style={{
            //         display: "flex",
            //         alignItems: "center",
            //         justifyContent: "center",
            //         padding: "0px 5px",
            //       }}
            //       onClick={() => {
            //         onCahngeEvent(

            //         );
            //       }}
            //     >
            //       <img src={require("../../../assets/images/close.png")} style={{ width: 20, height: 20 }} />
            //     </div>
            //   );
            // },
          }}
        />
        {rightAction}
      </FloatLabel>
    </Form.Item>
  );
};

const areEqual = (prev, next) => {
  return (
    prev.label === next.label &&
    prev.placeholder === next.placeholder &&
    prev.noRecordText === next.noRecordText &&
    prev.selectedId === next.selectedId &&
    prev.searchTextKey === next.searchTextKey &&
    prev.disabled === next.disabled &&
    prev.action === next.action &&
    JSON.stringify(prev.filter) === JSON.stringify(next.filter) &&
    prev.controller === next.controller &&
    prev.selectedName === next.selectedName &&
    prev.errorMessage === next.errorMessage &&
    prev.value == next.value &&
    prev.show === next.show &&
    prev.isMulti === next.isMulti &&
    prev.customData === next.customData
  );
};

export default React.memo(FormSelect, areEqual);
